// eslint-disable-line no-unused-vars
import {
  CLEAR_SEARCH_HINTS,
  CLEAR_SEARCH_RESULTS,
  SEARCH_COMPLETE,
  SEARCH_IN_PROGRESS,
  SET_SEARCH_HINTS,
  SET_SEARCH_RESULTS,
  SET_SEARCH_TEXT,
  SET_SEARCH_UI_PHASE,
  SEARCH_UI_PHASE_RESULTS,
  SEARCH_UI_PHASE_ARTIST_SELECTED,
  ARTIST_SELECTED,
  CLEAR_SEARCH_TEXT
} from "../reducers/search-reducer";

import {
  bestRecord,
  browserCountryCode,
  debounce,
  fetchWithAuth,
  mk,
  mkapi
} from "../helpers";
import _ from "lodash";
import {
  addRecordsToMap,
  fetchObjectWithHref,
  clearSelectedRecord
} from "./record-actions";
import { reportEvent } from "../analytics";
import { setPickedItem } from "./ui-actions";

export function setSearchResults(results) {
  return function(dispatch) {
    const records = _.get(results, "albums.data");
    records && dispatch(addRecordsToMap(records));
    dispatch({ type: SET_SEARCH_RESULTS, results });
  };
}

export function artistSelectedWithRecord(record) {
  return async function(dispatch, getState) {
    // load album with artists
    dispatch(setSearchUIPhase(SEARCH_UI_PHASE_ARTIST_SELECTED));
    const catalogRecord = await fetchObjectWithHref(
      bestRecord(getState(), record).href,
      { include: "artists" }
    );
    const _artist = _.get(catalogRecord, "relationships.artists.data[0]");
    const artist = await fetchObjectWithHref(_artist.href, {
      include: "albums"
    });
    dispatch(artistSelected(artist, false));
  };
}
export function artistSelected(artist, showBack = true) {
  return function(dispatch) {
    const records = _.get(artist, "relationships.albums.data");
    records && dispatch(addRecordsToMap(records));
    dispatch({ type: ARTIST_SELECTED, artist, showBack });
  };
}

export function clearArtistSelection() {
  return function(dispatch) {
    dispatch({ type: ARTIST_SELECTED, artist: null });
  };
}

export function searchResultArtistClicked(artist, showBack) {
  return function(dispatch) {
    dispatch(setSearchUIPhase(SEARCH_UI_PHASE_ARTIST_SELECTED));
    dispatch(artistSelected(artist, showBack));
  };
}

export async function fetchSearchHints(term) {
  const countryCode = browserCountryCode();
  const url = `catalog/${countryCode}/search/hints?term=${term}`;

  const response = await fetchWithAuth(url);
  return _.get(response, "results.terms");
}

export function performSearch(term) {
  return function(dispatch, getState) {
    const state = getState();
    const lastSearchTerm = state.search.text;
    dispatch({ type: SEARCH_IN_PROGRESS });
    const countryCode = browserCountryCode();
    const url = `catalog/${countryCode}/search?term=${term}&types=albums,artists,songs&include=albums,songs`;
    dispatch(setPickedItem({ key: "searchResults", kind: "search" }));

    fetchWithAuth(url).then(results => {
      dispatch({ type: SEARCH_COMPLETE });
      if (term !== lastSearchTerm && results && results.length > 0);
      // is search query an exact match for just 1 artist name (not 0, not multiple)?
      // if so, just show the artist result page.
      const artistsNames = _.get(results, "results.artists.data", []).map(
        artist => artist.attributes.name
      );
      const numMatches = artistsNames.reduce((acc, name) => {
        if (_.lowerCase(name) === _.lowerCase(term)) return acc + 1;
        else return acc;
      }, 0);
      if (numMatches === 1) {
        const firstArtist = _.get(results, "results.artists.data")[0];
        dispatch(artistSelected(firstArtist));
        dispatch(setSearchUIPhase(SEARCH_UI_PHASE_ARTIST_SELECTED));
      } else dispatch(setSearchResults(results));
    });
  };
}

/* records returned in performSearch look like
{
    "id": "1456540586",
    "type": "albums",
    "href": "/v1/catalog/us/albums/1456540586",
    "attributes": {
      "artwork": {
        ...
        "url": "https://is3-ssl.mzstatic.com/image/thumb/Music123/v4/31/2f/3b/312f3bc9-ae48-dcff-17e4-f4701c466051/Cover_TEL.jpg/{w}x{h}bb.jpeg",
        ...
      },
      "artistName": "Tel",
      "isSingle": false,
      "url": "https://music.apple.com/us/album/lowlife/1456540586",
      "isComplete": true,
      "genreNames": [
        ...
      ],
      "trackCount": 5,
        ...
      "releaseDate": "2019-03-29",
      "name": "Lowlife",
      "recordLabel": "Aural Music",
      "copyright": "℗ 2019 Aural Music",
      "playParams": {
        "id": "1456540586",
        "kind": "album"
      }
    }
 */

/* vs ... records returned from /recently-added endpoint:
{
    "id": "l.ccsrbRh",
    "type": "library-albums",
    "href": "/v1/me/library/albums/l.ccsrbRh",
    "attributes": {
      "trackCount": 11,
      "artistName": "Jimi Hendrix",
      "artwork": {
        "width": 1200,
        "height": 1200,
        "url": "https://is4-ssl.mzstatic.com/image/thumb/Music/42/46/64/mzi.iizkzlsy.jpg/{w}x{h}bb.jpeg"
      },
      "playParams": {
        "id": "l.ccsrbRh",
        "kind": "album",
        "isLibrary": true
      },
      "name": "Blues"
    }
  },
  "time": "1562181665.372"
}
 */

export function setSearchUIPhase(phase) {
  return function(dispatch) {
    dispatch({ type: SET_SEARCH_UI_PHASE, phase });
  };
}

export function clearSearchUIPhase(phase) {
  return function(dispatch) {
    dispatch(clearSearchHints());
    dispatch(setSearchUIPhase(null));
  };
}

export function setSearchHints(hints) {
  return function(dispatch) {
    // hints &&
    //   debounce("instantsearch", () => dispatch(performSearch(hints[0]), 100));
    dispatch({ type: CLEAR_SEARCH_RESULTS, hints });
    dispatch({ type: SET_SEARCH_HINTS, hints });
  };
}

export function clearSearchHints() {
  return function(dispatch) {
    dispatch({ type: CLEAR_SEARCH_HINTS });
    dispatch(clearSearchResults());
  };
}

export function clearSearchResults() {
  return function(dispatch) {
    dispatch({ type: CLEAR_SEARCH_RESULTS });
  };
}

export function getSearchHints(text) {
  return async function(dispatch) {
    dispatch({type:"getSearchHints", text})
    if (text && text.length > 0) {
      dispatch({type:"fetching search hints", text})
      const terms = await fetchSearchHints(text);
      dispatch({type:"search hints fetched", text, terms})

      return text && text.length > 0 && terms && terms.length > 0
        ? dispatch(setSearchHints(terms))
        : dispatch(clearSearchHints());
    } else {
      dispatch(clearSearchHints());
    }
  };
}

export function setSearchText(text, showBack) {
  return function(dispatch) {
    dispatch({ type: SET_SEARCH_TEXT, text });
    if (text && text.length > 0) {
      dispatch(setSearchUIPhase(SEARCH_UI_PHASE_RESULTS));
    } else {
      dispatch(clearSearchUIPhase());
    }
    if (text && text.length > 2) {
      dispatch(performSearch(text));
      dispatch(clearSearchHints());
      //debounce(dispatch(getSearchHints(text)))
    } else {
      dispatch(clearSearchHints());
      dispatch(clearSearchResults());
    }
  };
}
