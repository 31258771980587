import { jlog } from "../helpers";

export const ERROR = "ERROR";
export const MEASURE = "MEASURE";
const initialState = {
  error: null,
  measure: {
    totalMemoryUsageBytesArray: []
  }
};

const ServiceStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case ERROR: {
      jlog({ error: action });
      return { error: { message: action.message } };
    }
    case MEASURE: {
      const measureClone = { ...state.measure };
      const totalMemoryUsageBytesArray = [
        ...measureClone.totalMemoryUsageBytesArray
      ];
      const totalMemoryUsageBytes = action.totalMemoryUsageBytes;
      totalMemoryUsageBytes &&
        totalMemoryUsageBytesArray.push(totalMemoryUsageBytes);
      measureClone.totalMemoryUsageBytesArray = totalMemoryUsageBytesArray;
      return { ...state, measure: measureClone };
    }
    default:
      return state;
  }
};

export default ServiceStatusReducer;
