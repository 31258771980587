import { jlog } from "../helpers";

const genres = {
  Rock: [
    "singer/songwriter",
    "rock",
    "alternative & punk",
    "alternative",
    "pop",
    "folk",
    "indie rock",
    "metal"
  ],
  "R&B/Soul": ["r&b/soul"],
  "Rap & Hip-Hop": ["hip hop/rap", "hip-hop/rap", "hip-hop", "urbano latino"],
  Electronic: ["electronic", "electronica/dance", "house", "dance", "new age"],
  Reggae: ["reggae"],
  Jazz: ["jazz", "fusion", "vocal"],
  Classical: ["classical"],
  "Everything else": ["soundtrack", "world"]
};

export function isInGenre(genre, candidate) {
  if (!candidate) return false;
  if (!genres[genre]) return false;
  const ret = genres[genre].includes(candidate.toLowerCase());
  return ret;
}
