import React, { useState } from "react";
import styles from "./connected-property-switch.module.scss";
import connect from "react-redux/es/connect/connect";
import Switch from "../Controls/Switch/switch";
import { setSwitchValue, flash } from "../../actions/ui-actions";
import { isMobile, jlog } from "../../helpers";
import { store } from "../../index";

function ConnectedPropertySwitchComponent(props) {
  const [timesChanged, setTimesChanged] = useState(0);
  function onChange(position) {
    props.setSwitchValue(props.property, position);
    timesChanged === 0 &&
      props.onFirstOn &&
      position === 1 &&
      props.onFirstOn();
    setTimesChanged(t => t + 1);
  }

  return (
    <div className={styles.container}>
      <div className={styles.switch}>
        <Switch
          backgroundColor={props.palette.lighterBackgroundColor1}
          color={props.palette.backgroundColor}
          onChange={onChange}
          position={props.position}
        />
      </div>
      <div className={styles.label}>{props.label}</div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    position: state.ui.switches[ownProps.property],
    palette: state.ui.palette
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSwitchValue: (key, value) => {
      dispatch(setSwitchValue(key, value));
    }
  };
};

const ConnectedPropertySwitch = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedPropertySwitchComponent);

export default ConnectedPropertySwitch;

export function AutoPreviewSwitch(props) {
  return (
    !isMobile() && (
      <ConnectedPropertySwitch
        property={"autoPreview"}
        label={"Preview-on-hover"}
        onFirstOn={() =>
          store.dispatch(
            flash(
              "Note: Your browser may prevent auto-preview from working until you've interacted with shelf.fm for a given period of time."
            )
          )
        }
      />
    )
  );
}
