import React from "react";
import { jlog } from "../../helpers";
import connect from "react-redux/es/connect/connect";
import styles from "./debug-info.module.scss";
import { constant, Constants, flag, Flags } from "../../helpers/flags";

function DebugInfo(props) {
  const { klass, log, showDebugLog } = props;
  //if (!showDebugLog) return null;
  const { playbackStateName } = props;
  const elements = [];
  constant(Constants.debugInfoToShow) &&
    elements.push(
      <div className={styles.playbackState}>{playbackStateName}</div>
    );
  return (
    <div className={styles[klass]}>
      {log.map(msg => (
        <div>{msg}</div>
      ))}
      {/* {constant(Constants.debugInfoToShow) && (
        <div className={styles.playbackState}>{playbackStateName}</div>
      )} */}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    playbackStateName: state.playback.playbackStateName,
    log: state.debug.log,
    showDebugLog: state.debug.showDebugLog
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DebugInfo);
