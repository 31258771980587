import Shelf from "../components/Shelf/shelf";
import React from "react";
import {
  areRecordsTheSame,
  bestRecord,
  browserCountryCode,
  jlog,
  slugForRecord
} from "../helpers";
import _ from "lodash";
import { tryRecords } from "../pages/TryPage/try-records";

export const SET_VISIBILITY = "SET_VISIBILITY";
export const LOADED_OTHER_RELEASES = "LOADED_OTHER_RELEASES";
export const ADD_RECORDS_TO_MAP = "ADD_RECORDS_TO_MAP";
export const ADD_RECORDS_TO_MUSICBRAINZ_ONLY_MAP =
  "ADD_RECORDS_TO_MUSICBRAINZ_ONLY_MAP";
export const ADD_RECORDS_TO_SHELF = "ADD_RECORDS_TO_SHELF";
export const CLEAR_USER_DATA = "CLEAR_USER_DATA";
export const HARD_REFRESH_SHELF = "HARD_REFRESH_SHELF";
export const INSERT_RECORDS_ON_TOP_OF_SHELF = "INSERT_RECORDS_ON_TOP_OF_SHELF";
export const RATINGS_LOADED = "RATINGS_LOADED";
export const SHELF_TYPE_APPLE_MUSIC_RECENTS = "SHELF_TYPE_APPLE_MUSIC_RECENTS";
export const SHELF_TYPE_APPLE_MUSIC_NEW_RELEASES =
  "SHELF_TYPE_APPLE_MUSIC_NEW_RELEASES";
export const SHELF_TYPE_LIBRARY_RECORDS = "SHELF_TYPE_LIBRARY_RECORDS";
export const SHELF_TYPE_USER = "SHELF_TYPE_APPLE_USER";
export const SHELF_TYPE_EXTERNAL_SOURCE = "SHELF_TYPE_EXTERNAL_SOURCE";
export const SHELF_TYPE_TRY = "SHELF_TYPE_TRY";
export const SHELF_TYPE_PLAYLISTS = "SHELF_TYPE_PLAYLISTS";
export const SET_SHELF_CURSOR = "SET_SHELF_CURSOR";
export const STARTED_LOADING_RECENTS_FROM_APPLE_MUSIC =
  "STARTED_LOADING_RECENTS_FROM_APPLE_MUSIC";
export const DONE_LOADING_RECENTS_FROM_APPLE_MUSIC =
  "DONE_LOADING_RECENTS_FROM_APPLE_MUSIC";
export const STARTED_DOING_RECENTLY_PLAYED_PLAYLIST_STUFF =
  "STARTED_DOING_RECENTLY_PLAYED_PLAYLIST_STUFF";
export const DONE_DOING_RECENTLY_PLAYED_PLAYLIST_STUFF =
  "DONE_DOING_RECENTLY_PLAYED_PLAYLIST_STUFF";

export const SHELF_TYPE_APPLE_MUSIC_RECOMMENDED =
  "SHELF_TYPE_APPLE_MUSIC_RECOMMENDED";
export const REMOVE_RECORD_FROM_SHELF = "REMOVE_RECORD_FROM_SHELF";
export const LOADING_EXTERNAL_SOURCE = "LOADING_EXTERNAL_SOURCE";
export const EXTERNAL_SOURCE_LOADED = "EXTERNAL_SOURCE_LOADED";
export const EXTERNAL_SOURCE_LOADING_FAILED = "EXTERNAL_SOURCE_LOADING_FAILED";
export const EXTERNAL_SOURCE_SPINITRON_TOP_50 = "Top 50";
export const EXTERNAL_SOURCE_LABEL_RECORDS = "New Releases";
export const EXTERNAL_SOURCE_DETAILED_INFO_FOR_RECORD = "record details";
export const EXTERNAL_SOURCE_DISCOGRAPHY_FOR_RECORD = "discography for record";
export const EXTERNAL_SOURCE_APPLE_MUSIC_NEW_RELEASES = "New Releases (AM)";
export const UNLOADED = "UNLOADED";
export const LOADING = "LOADING";
export const LOADED = "LOADED";
export const FAILED = "FAILED";
export const SET_RECENTLY_PLAYED_PLAYLIST_ID =
  "SET_RECENTLY_PLAYED_PLAYLIST_ID";
export const MOVE_RECORD_TO_TOP_OF_SHELF = "MOVE_RECORD_TO_TOP_OF_SHELF";
export const SOURCE_RECENTLY_PLAYED_HERE = "SOURCE_RECENTLY_PLAYED_HERE";
export const SOURCE_RECENTLY_PLAYED_AM = "SOURCE_RECENTLY_PLAYED_AM";
export const SOURCE_RECENTLY_ADDED_AM = "SOURCE_RECENTLY_ADDED_AM";
export const UPDATE_RATING_MAP = "UPDATE_RATING_MAP";
export const SAVE_RECENTLY_PLAYED_PLAYLIST_RECORDS =
  "SAVE_RECENTLY_PLAYED_PLAYLIST_RECORDS";
export const SET_SHELF_SORT_OPTION = "SET_SHELF_SORT_OPTION";
export const ADD_RECORD_TO_LABEL_TO_ARTIST_MAP =
  "ADD_RECORD_TO_LABEL_TO_ARTIST_MAP";
export const DISCOGRAPHY_LOADED_FOR_RECORD = "DISCOGRAPHY_LOADED_FOR_RECORD";
export const DETAILED_INFO_LOADED_FOR_RECORD =
  "DETAILED_INFO_LOADED_FOR_RECORD";
export const RECORD_DETAILS_LOADED_BASED_ON_SLUG =
  "RECORD_DETAILS_LOADED_BASED_ON_SLUG";
export const RECORD_DETAILS_LOADED_BASED_ON_HREF =
  "RECORD_DETAILS_LOADED_BASED_ON_HREF";
export const REMOVE_RECORD_FROM_RECORD_STORY_MAP =
  "REMOVE_RECORD_FROM_RECORD_STORY_MAP";
export const RECORD_SELECTED = "RECORD_SELECTED";
export const TURN_RECORD_OVER = "TURN_RECORD_OVER";
export const TURN_BIG_RECORD_TO_SIDE = "TURN_BIG_RECORD_TO_SIDE";
export const TRACKS_LOADED_FOR_RECORD = "TRACKS_LOADED_FOR_RECORD";
export const SET_MOST_RECENT_NEW_RELEASE_SEEN_DATE =
  "SET_MOST_RECENT_NEW_RELEASE_SEEN_DATE";
export const WHOMP_STATE_WITH_SAVED_STATE_JSON =
  "WHOMP_STATE_WITH_SAVED_STATE_JSON";
export const SET_LOADING_STEP = "SET_LOADING_STEP";
export const ADD_PLAYLISTS_TO_SHELF = "ADD_PLAYLISTS_TO_SHELF";
export const SET_SELECTION_ANIMATION_STATE = "SET_SELECTION_ANIMATION_STATE";
export const PLAYLISTS_LOADED = "PLAYLISTS_LOADED";
export const TRACK_ADDED_TO_PLAYLIST = "TRACK_ADDED_TO_PLAYLIST";
export const SET_SHELF_LAYOUT = "SET_SHELF_LAYOUT";

export const SCRATCH_SHELF_INDEX = 3;
export const RECENTS_SHELF_INDEX = 0;

const shelfShape = {
  name: "",
  cursor: 0,
  layoutCode: "s",
  type: SHELF_TYPE_USER,
  records: []
};

const recentlyPlayedAddedShelf = {
  ...shelfShape,
  name: "Recently Played/Added",
  type: SHELF_TYPE_APPLE_MUSIC_RECENTS,
  sortOptionIndex: 0,
  sortOptions: [
    {
      id: "index",
      name: "Index",
      sortRecordsBy: "index"
    },
    {
      id: "label",
      name: "Label",
      sortRecordsBy: "attributes.recordLabel"
    }
  ]
};

const libraryRecordsShelf = {
  ...shelfShape,
  sortOptionIndex: 0,
  name: "Records",
  type: SHELF_TYPE_LIBRARY_RECORDS
};

const recentlyPlayedShelf = {
  ...shelfShape,
  name: "Recently Played",
  type: SHELF_TYPE_APPLE_MUSIC_RECENTS
};

const recentlyAddedShelf = {
  ...shelfShape,
  name: "Recently Added",
  type: SHELF_TYPE_APPLE_MUSIC_RECENTS
};

const newReleasesShelf = {
  ...shelfShape,
  name: "New Releases",
  type: SHELF_TYPE_APPLE_MUSIC_NEW_RELEASES
};

const recommendedShelf = {
  ...shelfShape,
  name: "New Releases",
  type: SHELF_TYPE_APPLE_MUSIC_RECOMMENDED
};

const scratchShelf = {
  ...shelfShape,
  layoutCode: "m",

  name: "Scratch shelf",
  type: SHELF_TYPE_EXTERNAL_SOURCE
};

const orbitShelf = {
  ...shelfShape,
  layoutCode: "m",
  name: "Orbit shelf",
  type: SHELF_TYPE_EXTERNAL_SOURCE
};

const labelRecordsShelf = {
  ...shelfShape,
  layoutCode: "m",
  name: "New Releases",
  type: SHELF_TYPE_EXTERNAL_SOURCE,
  index: 4,
  sortOptionIndex: 0,
  sortOptions: [
    {
      id: "releaseDate",
      name: "Release Date",
      sortRecordsBy: "attributes.releaseDate",
      reverse: true
    }
  ]
};

const playlistsShelf = {
  name: "Playlists",
  layoutCode: "m",
  cursor: 0,
  type: SHELF_TYPE_PLAYLISTS,
  playlists: []
};

const t = tryRecords;
const tryShelf = {
  ...shelfShape,
  name: "Places to start",
  allowRefresh: false,
  type: SHELF_TYPE_TRY,
  records: t
};

export const AnimationStates = {
  Out: "Out",
  OutComplete: "OutComplete", // ie page load complete
  In: "In",
  GoingInPrep: "GoingInPrep", // overlay image is loading
  GoingIn: "GoingIn", // overlayimage is loaded
  GoingOut: "GoingOut"
};

const lightweightInitialState = {
  selected: null,
  selectionAnimationState: AnimationStates.Out,
  mostRecentNewReleaseSeen: null,
  loadingStep: null,
  turnedOverRecordId: null,
  bigRecordSide: 0,
  loadingRecentsFromAppleMusic: false,
  userDataLoaded: false
};

const initialState = {
  whomped: false,
  ratingsMap: {},
  externalSourceLoadState: {},
  recentlyPlayedPlaylistRecords: [],
  recentsShelfIndex: RECENTS_SHELF_INDEX,
  appleMusicNewReleasesShelfIndex: 1,
  recommendedShelfIndex: 2,
  scratchShelfIndex: SCRATCH_SHELF_INDEX,
  labelRecordsShelfIndex: 4,
  tryShelfIndex: 5,
  recentlyPlayedShelfIndex: 6,
  recentlyAddedShelfIndex: 7,
  libraryRecordsShelfIndex: 8,
  playlistsShelfIndex: 9,
  orbitShelfIndex: 10,
  shelves: [
    recentlyPlayedAddedShelf,
    newReleasesShelf,
    recommendedShelf,
    scratchShelf,
    labelRecordsShelf,
    tryShelf,
    recentlyPlayedShelf,
    recentlyAddedShelf,
    libraryRecordsShelf,
    playlistsShelf,
    orbitShelf
  ],
  playlists: [],
  visibilityMap: {},
  idToRecordMap: {},
  mbidToMusicbrainzOnlyMap: {},
  slugToRecordMap: {},
  labelToArtistMap: {},
  recordIdToDetailedInfoMap: {},
  recordSlugToDiscographyMap: {},
  recordSlugsWithNoImages: {},
  recordIdToTracksMap: {},
  recordIdToOtherReleasesMap: {},
  recordIdToCoverArtMetaDataMap: {},
  recordIdToCoverArtImageMap: {}
};

/* records look like
    "id": "l.ccsrbRh",
    "type": "library-albums",
    "href": "/v1/me/library/albums/l.ccsrbRh",
    "attributes": {
      "trackCount": 11,
      "artistName": "Jimi Hendrix",
      "artwork": {
        "width": 1200,
        "height": 1200,
        "url": "https://is4-ssl.mzstatic.com/image/thumb/Music/42/46/64/mzi.iizkzlsy.jpg/{w}x{h}bb.jpeg"
      },
      "playParams": {
        "id": "l.ccsrbRh",
        "kind": "album",
        "isLibrary": true
      },
      "name": "Blues"
    }
  },
  "time": "1562181665.372"
}
 */

function updateIdToRecordMap(idToRecordMap, records) {
  records.map(record => {
    idToRecordMap[record.id] = record;
    return null;
  });
  return idToRecordMap;
}

export function LightweightRecordReducer(
  state = lightweightInitialState,
  action
) {
  switch (action.type) {
    case CLEAR_USER_DATA: {
      return initialState;
    }
    case SET_SELECTION_ANIMATION_STATE: {
      return { ...state, selectionAnimationState: action.state };
    }
    case WHOMP_STATE_WITH_SAVED_STATE_JSON:
      return action.state;
    case SET_MOST_RECENT_NEW_RELEASE_SEEN_DATE:
      return { ...state, mostRecentNewReleaseSeen: action.date };
    case TURN_BIG_RECORD_TO_SIDE:
      return { ...state, bigRecordSide: action.side };
    case TURN_RECORD_OVER:
      const record = action.record;
      if (!record) return state;
      const domId = action.domId;
      var newTurnedOverRecordId = null;
      var newTurnedOverDomId = null;
      const newDomId = _.get(state.turnedOverRecord, "domId");
      if (!newDomId || newDomId !== domId) {
        newTurnedOverDomId = domId;
        newTurnedOverRecordId = record.id;
      }
      return {
        ...state,
        turnedOverRecord: {
          recordId: newTurnedOverRecordId,
          domId: newTurnedOverDomId
        }
      };
    case SET_LOADING_STEP: {
      return {
        ...state,
        loadingStep: action.step,
        loadingCount: action.count
      };
    }
    case RECORD_SELECTED: {
      let newSelected;
      // if (action.selected === null) {
      //   newSelected = null;
      // } else if (action.selected.record && !action.selected.layoutRect) {
      //   newSelected = { ...state.selected, record: action.selected.record };
      // } else {
      //   newSelected = action.selected;
      // }
      return { ...state, selected: action.selected };
    }
    case DONE_LOADING_RECENTS_FROM_APPLE_MUSIC: {
      return {
        ...state,
        loadingRecentsFromAppleMusic: false,
        userDataLoaded: true
      };
    }
    default:
      return state;
  }
}

export function RecordReducer(state = initialState, action) {
  let shelfIndex, shelf, cursor, extra, newShelves, record, records, newShelf;
  switch (action.type) {
    case SET_SHELF_LAYOUT:
      const { shelfId, layout } = action;
      const shelvesClone = [...state.shelves];
      shelvesClone[shelfId].layoutCode = layout;
      return { ...state, shelves: shelvesClone };
    case PLAYLISTS_LOADED:
      return { ...state, playlists: action.playlists };
    case SET_VISIBILITY:
      const mapC = { ...state.visibilityMap };
      mapC[action.id] = action.visible;
      return { ...state, visibilityMap: mapC };
    case LOADED_OTHER_RELEASES:
      record = action.record;
      const otherReleases = action.otherReleases;
      const newORMap = state.recordIdToOtherReleasesMap;
      newORMap[record.catalogAlbumId] = otherReleases;
      return { ...state, recordIdToOtherReleasesMap: newORMap };
    case "SELECT_COVER_ART":
      const newArtMap = { ...state.recordIdToCoverArtImageMap };
      newArtMap[action.record.id] = action.image;
      return { ...state, recordIdToCoverArtImageMap: newArtMap };
    case "COVER_ART_METADATA_LOADED":
      const newRecordIdToCoverArtMetaDataMap = {
        ...state.recordIdToCoverArtMetaDataMap
      };
      newRecordIdToCoverArtMetaDataMap[action.record.id] = action.json;
      return {
        ...state,
        recordIdToCoverArtMetaDataMap: newRecordIdToCoverArtMetaDataMap
      };

    case DISCOGRAPHY_LOADED_FOR_RECORD:
      record = action.record;
      records = action.records;
      const { recordSlugToDiscographyMap } = state;
      const newMap = { ...recordSlugToDiscographyMap };
      newMap[slugForRecord(record)] = action.records;
      return { ...state, recordSlugToDiscographyMap: newMap };
    case TRACKS_LOADED_FOR_RECORD:
      record = action.record;
      const tracks = action.tracks;
      const { recordIdToTracksMap } = state;
      const __newMap = { ...recordIdToTracksMap };
      __newMap[record.id] = tracks;
      return { ...state, recordIdToTracksMap: __newMap };
    case DETAILED_INFO_LOADED_FOR_RECORD:
      const { recordIdToDetailedInfoMap } = state;
      const _newMap = { ...recordIdToDetailedInfoMap };
      _newMap[action.record.id] = action.detailedInfo;
      return { ...state, recordIdToDetailedInfoMap: _newMap };
    case REMOVE_RECORD_FROM_RECORD_STORY_MAP:
      const recordSlug = slugForRecord(action.record);
      const recordSlugsWithNoImagesCopy = { ...state.recordSlugsWithNoImages };
      recordSlugsWithNoImagesCopy[recordSlug] = action.record;
      return { ...state, recordSlugsWithNoImages: recordSlugsWithNoImagesCopy };

    case ADD_RECORD_TO_LABEL_TO_ARTIST_MAP:
      record = action.record;
      if (!record.attributes.recordLabel) return state;
      const { labelToArtistMap } = state;
      const newLabelToArtistMap = { ...labelToArtistMap };
      const value = newLabelToArtistMap[record.attributes.recordLabel] || [];
      value.push(record.attributes.artistName);
      newLabelToArtistMap[record.attributes.recordLabel] = value;
      return { ...state, labelToArtistMap: newLabelToArtistMap };

    case SET_SHELF_SORT_OPTION:
      shelfIndex = action.shelfIndex;
      const sortOptionIndex = action.sortOptionIndex;
      shelf = state.shelves[shelfIndex];
      newShelf = { ...state.shelves[shelfIndex], ...{ sortOptionIndex } };
      const ___newShelves = [...state.shelves];
      ___newShelves[shelfIndex] = newShelf;
      return { ...state, shelves: ___newShelves };

    case SET_SHELF_CURSOR:
      shelfIndex = action.shelfIndex;
      //const records = action.records
      cursor = action.cursor;
      shelf = state.shelves[shelfIndex];
      newShelf = { ...state.shelves[shelfIndex], ...{ cursor } };
      const _newShelves = [...state.shelves];
      _newShelves[shelfIndex] = newShelf;
      return { ...state, shelves: _newShelves };
    case RATINGS_LOADED:
      const { slugRatings } = action;
      const ratingsMap = slugRatings
        ? Object.entries(slugRatings).reduce(
            (acc, [slug, value]) => ({ ...acc, [slug]: value }),
            {}
          )
        : {};
      return { ...state, ratingsMap: { ...ratingsMap, ...state.ratingsMap } };
    case UPDATE_RATING_MAP:
      const { rating } = action;
      record = action.record;
      const newRatingsMap = { ...state.ratingsMap };
      newRatingsMap[slugForRecord(record, true)] = rating;
      return { ...state, ratingsMap: newRatingsMap };
    case ADD_RECORDS_TO_MAP:
      const mapCopy = { ...state.idToRecordMap };
      action.records.map(record => (mapCopy[record.id] = record));
      return { ...state, idToRecordMap: mapCopy };
    case ADD_RECORDS_TO_MUSICBRAINZ_ONLY_MAP:
      const mbMapCopy = { ...state.mbidToMusicbrainzOnlyMap };
      action.records.map(
        record => (mbMapCopy[record.attributes.musicbrainzId] = record)
      );
      return { ...state, mbidToMusicbrainzOnlyMap: mbMapCopy };
    case MOVE_RECORD_TO_TOP_OF_SHELF:
      // find the record, set its index to zero.
      shelfIndex = action.shelfIndex;
      const _extra = action.extra;
      const recordToMoveIndex = action.index;
      shelf = state.shelves[shelfIndex];
      //cursor = shelf.cursor;
      const newCursor = 0; //cursor + 1;
      newShelf = { ...shelf };

      const _newRecords = newShelf.records.map((r, i) => {
        const _newExtra = { ..._extra, ...r.extra };
        return i === recordToMoveIndex
          ? { ...r, index: newCursor, extra: _newExtra }
          : r;
      });
      newShelf.records = _newRecords;
      //newShelf.cursor = newCursor;
      const __newShelves = [...state.shelves];
      __newShelves[shelfIndex] = newShelf;
      return { ...state, shelves: __newShelves };
    case ADD_PLAYLISTS_TO_SHELF:
      const playlists = action.playlists;
      shelfIndex = action.shelfIndex;
      shelf = state.shelves[shelfIndex];
      const _playlists = playlists.filter(
        playlist =>
          !(shelf.playlists || []).map(p => p.id).includes(playlist.id)
      );

      newShelves = [...state.shelves];
      newShelves[shelfIndex] = {
        ...state.shelves[shelfIndex],
        ...{ playlists: playlists }
      };
      return { ...state, shelves: newShelves };
    case ADD_RECORDS_TO_SHELF:
      shelfIndex = action.shelfIndex;
      records = action.records;
      shelf = state.shelves[shelfIndex];
      cursor = shelf.cursor;
      const shelfRecords = [...(shelf.records || [])];

      for (var _record of records) {
        // not already in there?
        //if (_.findIndex(shelfRecords, ["id", record.id]) === -1) {
        // add it at the cursor point
        _record.index = cursor || 0;
        // move records to the right
        const recordsToTheRight = shelfRecords.filter(r => r.index >= cursor);
        for (let r of recordsToTheRight) r.index += 1;

        const _extra = { ..._record.extra };
        _extra.cursor = cursor;
        // add record to the array (index property represents place in sort)
        shelfRecords.push({ ..._record, extra: _extra });

        // increment cursor
        cursor = cursor + 1;
        //}
      }
      newShelves = [...state.shelves];
      newShelves[shelfIndex] = {
        ...state.shelves[shelfIndex],
        ...{ cursor: cursor },
        ...{ records: shelfRecords }
      };
      return { ...state, shelves: newShelves };
    case REMOVE_RECORD_FROM_SHELF:
      shelfIndex = action.shelfIndex;
      const recordIndex = action.record.index;
      newShelves = [...state.shelves];
      newShelf = { ...state.shelves[shelfIndex] };

      const newRecords = [...newShelf.records].filter(r => {
        return r.index !== recordIndex;
      });
      newShelf.records = newRecords;
      newShelves[shelfIndex] = newShelf;
      return { ...state, shelves: newShelves };

    case HARD_REFRESH_SHELF: {
      const { shelfIndex } = action;
      const newState = { ...state };
      const newShelves = [...state.shelves];
      newShelves[shelfIndex].records = [];
      newShelves[shelfIndex].cursor = 0;
      newState.shelves = newShelves;
      return { ...newState };
    }
    case CLEAR_USER_DATA: {
      return initialState;
    }
    case STARTED_LOADING_RECENTS_FROM_APPLE_MUSIC: {
      return { ...state, loadingRecentsFromAppleMusic: true };
    }
    case STARTED_DOING_RECENTLY_PLAYED_PLAYLIST_STUFF: {
      return {
        ...state,
        loadingStep: "recently played playlist"
      };
    }
    case DONE_DOING_RECENTLY_PLAYED_PLAYLIST_STUFF: {
      return {
        ...state,
        loadingStep: null
      };
    }
    case LOADING_EXTERNAL_SOURCE: {
      const newExternalSourceLoadState = state.externalSourceLoadState;
      if (!newExternalSourceLoadState) return state;
      newExternalSourceLoadState[action.name] = LOADING;
      return {
        ...state,
        externalSourceLoadState: newExternalSourceLoadState,
        loadingStep: action.name
      };
    }
    case EXTERNAL_SOURCE_LOADED: {
      const newExternalSourceLoadState = state.externalSourceLoadState;
      if (!newExternalSourceLoadState) return state;
      newExternalSourceLoadState[action.name] = LOADED;
      return {
        ...state,
        externalSourceLoadState: newExternalSourceLoadState,
        loadingStep: null
      };
    }
    case EXTERNAL_SOURCE_LOADING_FAILED: {
      const newExternalSourceLoadState = state.externalSourceLoadState;
      if (!newExternalSourceLoadState) return state;
      newExternalSourceLoadState[action.name] = FAILED;
      return { ...state, externalSourceLoadState: newExternalSourceLoadState };
    }
    case SET_RECENTLY_PLAYED_PLAYLIST_ID: {
      return { ...state, recentlyPlayedPlaylistId: action.playlistId };
    }
    case SAVE_RECENTLY_PLAYED_PLAYLIST_RECORDS: {
      console.log("SAVE_RECENTLY_PLAYED_PLAYLIST_RECORDS");
      return {
        ...state,
        recentlyPlayedPlaylistRecords: action.records
      };
    }
    case RECORD_DETAILS_LOADED_BASED_ON_SLUG: {
      const mapCopy = { ...state.slugToRecordMap };
      mapCopy[slugForRecord(action.record)] = { ...action.record, ...record };
      return { ...state, slugToRecordMap: mapCopy };
    }

    default:
      return state;
  }
}
