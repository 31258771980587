import React, { useState, useEffect, useRef } from "react";
import { jlog } from "../../helpers";

export default function MusicbrainzCoverArt(props) {
  const {
    record: {
      attributes: { musicbrainzId }
    },
    onClick,
    onFail,
    onLoad
  } = props;
  const [size, setSize] = useState(props.size);
  const imgRef = useRef(null);
  const [loadState, setLoadState] = useState("unloaded");
  const [opacity, setOpacity] = useState(0);
  function onload() {
    jlog({ loadedMusicBrainzCoverArt: props.record.attributes.name });
    setOpacity(1);

    setLoadState("loaded");
    onLoad && onLoad();
  }
  function onerror() {
    jlog({ failed: props.record.attributes.name });
    setLoadState("failed");
    onFail && onFail();
  }
  useEffect(() => {
    imgRef.current.onload = onload;
    imgRef.current.onerror = onerror;
  }, []);

  useEffect(() => {
    setSize(props.size);
  }, [props.size]);

  return loadState === "failed" ? (
    <div></div>
  ) : (
    <img
      ref={imgRef}
      style={{ opacity, filter: "grayscale(100%)" }}
      height={size}
      width={size}
      onClick={onClick}
      src={`http://www.coverartarchive.org/release/${musicbrainzId}/front`}
    />
  );
}
