export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const CLEAR_SEARCH_TEXT = "CLEAR_SEARCH_TEXT";
export const SET_SEARCH_HINTS = "SET_SEARCH_HINTS";
export const CLEAR_SEARCH_HINTS = "CLEAR_SEARCH_HINTS";
export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";
export const CLEAR_SEARCH_RESULTS = "CLEAR_SEARCH_RESULTS";
export const SEARCH_IN_PROGRESS = "SEARCH_IN_PROGRESS";
export const SEARCH_COMPLETE = "SEARCH_COMPLETE";
export const SET_SEARCH_UI_PHASE = "SET_SEARCH_UI_PHASE";
export const SEARCH_UI_PHASE_RECOMMENDED = "SEARCH_UI_PHASE_RECOMMENDED";
export const SEARCH_UI_PHASE_RESULTS = "SEARCH_UI_PHASE_RESULTS";
export const SEARCH_UI_PHASE_ARTIST_SELECTED =
  "SEARCH_UI_PHASE_ARTIST_SELECTED";
export const SEARCH_UI_PHASE_NEW_RELEASES = "SEARCH_UI_PHASE_NEW_RELEASES";
export const ARTIST_SELECTED = "ARTIST_SELECTED";
export const SEARCH_FIELD_FOCUSED = "SEARCH_FIELD_FOCUSED";
const initialState = {
  uiPhase: null,
  selectedArtist: null,
  textEntered: false,
  searchInProgress: false,
  hints: null,
  text: null,
  searchFieldFocused: false
};

const SearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_RESULTS: {
      return { ...state, ...action.results };
    }
    case SEARCH_FIELD_FOCUSED: {
      return { ...state, searchFieldFocused: action.bool };
    }
    case SEARCH_IN_PROGRESS: {
      return { ...state, searchInProgress: true };
    }
    case SEARCH_COMPLETE: {
      return { ...state, searchInProgress: false };
    }

    case CLEAR_SEARCH_RESULTS: {
      return { ...state, results: null, selectedArtist: null };
    }
    case SET_SEARCH_TEXT: {
      const textEntered = action.text.length > 0;
      return { ...state, text: action.text, textEntered };
    }
    case CLEAR_SEARCH_TEXT: {
      return { ...state, textEntered: false };
    }
    case SET_SEARCH_HINTS: {
      return { ...state, hints: action.hints };
    }
    case CLEAR_SEARCH_HINTS: {
      return { ...state, hints: null };
    }
    case SEARCH_IN_PROGRESS: {
      return { ...state, searchInProgress: true };
    }
    case SEARCH_COMPLETE: {
      return { ...state, searchInProgress: false };
    }
    case SET_SEARCH_UI_PHASE: {
      return { ...state, uiPhase: action.phase };
    }
    case ARTIST_SELECTED: {
      return {
        ...state,
        selectedArtist: action.artist,
        showBack: action.showBack
      };
    }
    default:
      return state;
  }
};
export default SearchReducer;
