import React, { useState, useEffect } from "react";
import { areRecordsTheSame, bestRecord } from "../../helpers";
import _ from "lodash";
import {
  playPause,
  recordPlayPressed,
  recordSelected,
  stopPreviewingRecord,
  transitionPreviewToPlay
} from "../../actions/playback-actions";
import { artistSelectedWithRecord } from "../../actions/search-actions";
import {
  addRecord,
  deleteRecord,
  loadRecordIfNeeded,
  setSelectedRecord
} from "../../actions/record-actions";
import connect from "react-redux/es/connect/connect";
import styles from "./cover-hud.module.scss";
import { flag, Flags } from "../../helpers/flags";
import { TouchableOpacity } from "react-native-web";
import { setPaletteBasedOnRecordId } from "../../actions/ui-actions";

function CoverHud(props) {
  let {
    values,
    layoutRect,
    playing,
    isPlaying,
    textColor1,
    isPreviewing,
    active
  } = props;
  let valuesJsx = null;
  if (values && flag(Flags.showRecordDebugInfo)) {
    valuesJsx = Object.keys(values)
      .map(key => `${key}: ${values[key]}`)
      .map(value => <div className={styles.value}>{value}</div>);
  }
  const [record, setRecord] = useState(props.record);
  useEffect(() => {
    setRecord(props.record);
  }, [props.record.id]);
  // let playOpacity = 1;
  // let pauseOpacity = 0;
  // if (playing) {
  //   playOpacity=0;
  //   pauseOpacity=1;
  // }
  // if (playing &&!isPlaying) {
  //   playOpacity = 1;
  //   pauseOpacity = 0;
  // }
  let playStyle, pauseStyle;

  if (playing && isPlaying && !isPreviewing) {
    playStyle = { display: "none" };
    pauseStyle = { display: "inline" };
  } else {
    playStyle = { display: "inline" };
    pauseStyle = { display: "none" };
  }
  // let playStyle = playing ? { display: "none" } : { display: "inline" };
  // let pauseStyle = !playing ? { display: "none" } : { display: "inline" };
  // if (playing && !isPlaying) {
  //   playStyle = { display: "inline" };
  //   pauseStyle = { display: "none" };
  // }
  const opacity = active ? 1 : 0;
  //if (!record.id) return null;
  return (
    <div className={styles[props.klass || "default"]} style={{ opacity }}>
      <TouchableOpacity
        onPress={event => {
          if (props.isPreviewing) {
            props.transitionPreviewToPlay(event, record, layoutRect);
          } else if (props.isPlaying) {
            props.playPause(record);
          } else props.recordPlayPressed(event, record, layoutRect);
        }}
      >
        <div
          className={styles.playPauseButton}
          style={{ color: `#${textColor1}` }}
        >
          <i
            data-recordid={record.id}
            className="fa fa-pause"
            style={pauseStyle}
          />
          <i
            data-recordid={record.id}
            className="fa fa-play"
            style={playStyle}
          />
        </div>
      </TouchableOpacity>
      {valuesJsx}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  let record = bestRecord(state, ownProps.record);
  const { recentsShelfIndex, labelToArtistMap } = state.record;
  const _labelArtists = labelToArtistMap[record.attributes.recordLabel];
  const labelArtists = _labelArtists
    ? _.join(_.slice(_labelArtists, 0, 1), ", ")
    : null;

  const shelf = state.record.shelves[recentsShelfIndex];
  const addedRecords = shelf.records;
  const isAdded = addedRecords.reduce((acc, addedRecord) => {
    return areRecordsTheSame(record, addedRecord) | acc;
  }, false);
  const musicbrainzId = _.get(record, "attributes.musicbrainzId");
  const PlaybackStates = _.get(window, "MusicKit.PlaybackStates");
  return {
    textColor1: _.get(record, "attributes.artwork.textColor1"),
    bgColor: _.get(record, "attributes.artwork.bgColor"),
    playing:
      _.get(state, "playback.playbackState") &&
      _.get(state, "playback.playbackState") ===
        _.get(PlaybackStates, "playing"),
    previewReady: state.ui.previewReady,
    labelArtists,
    rating: state.record.ratingsMap[record.catalogAlbumId],
    musicbrainzOnly:
      musicbrainzId && state.record.mbidToMusicbrainzOnlyMap[musicbrainzId],
    palette: state.ui.palette,
    isAdded,
    loaded: !!record.attributes,
    record,
    recentsShelfIndex,
    currentlyPlayingRecord: state.playback.currentlyPlayingRecord,
    currentlyPlayingTrack: state.playback.currentlyPlayingTrack,
    isPlaying:
      record.id &&
      (record.id === _.get(state.playback, "mediaItem.id") ||
        record.id === _.get(state.playback, "mediaItem._container.id")),
    isPreviewing:
      record.id &&
      _.get(state, "playback.currentlyPreviewingRecord.id") === record.id,
    previewStatus: state.playback.previewStatus
  };
};

const mapDispatchToProps = dispatch => {
  return {
    stopPreviewingRecord: record => dispatch(stopPreviewingRecord(record)),
    artistClicked: async function(record) {
      dispatch(artistSelectedWithRecord(record), false);
    },
    loadRecordIfNeeded: record => {
      dispatch(loadRecordIfNeeded(record));
    },
    deleteRecord: (shelfIndex, id) => {
      dispatch(deleteRecord(shelfIndex, id));
    },
    addRecord: (shelfIndex, id) => {
      dispatch(addRecord(shelfIndex, id));
    },
    playPause: record => dispatch(playPause(record)),
    transitionPreviewToPlay: (event, record, layoutRect) => {
      console.log(layoutRect);
      dispatch(setSelectedRecord(record, layoutRect));
      dispatch(transitionPreviewToPlay(layoutRect));
    },

    recordPlayPressed: (event, record, layoutRect) => {
      event.stopPropagation();
      dispatch(setSelectedRecord(record, layoutRect));
      dispatch(recordPlayPressed(record));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoverHud);
