import React, { useState, useEffect } from "react";
import connect from "react-redux/es/connect/connect";
import {
  SEARCH_UI_PHASE_NEW_RELEASES,
  SEARCH_UI_PHASE_RECOMMENDED
} from "../../reducers/search-reducer";
import styles from "./catalogs.module.scss";
import Shelf from "../Shelf/shelf";
import {
  EXTERNAL_SOURCE_LABEL_RECORDS,
  EXTERNAL_SOURCE_SPINITRON_TOP_50,
  UNLOADED
} from "../../reducers/record-reducer";

//
function CatalogList(props) {
  const catalogs = props.allCatalogs.map(catalog => {
    const active = props.catalogActiveMap[catalog.id];
    const className = active ? "activeCatalog" : "inactiveCatalog";
    const activeBorderStyle = `0px solid ${catalog.color}`;
    const inactiveBorderStyle = `0px solid #777`;
    const style = active
      ? { borderTop: activeBorderStyle }
      : { opacity: 0.2, borderTop: inactiveBorderStyle };
    return (
      <div
        //onClick={()=>props.toggleCatalogActive(props.labelRecordsShelfIndex,catalog.id)}
        className={styles[className]}
        style={style}
      >
        <div
          className={styles.sourceIndicator}
          style={{ backgroundColor: catalog.color }}
        />{" "}
        {catalog.label}
      </div>
    );
  });
  return (
    <>
      <div className={styles.header}>New Releases</div>
      <div className={styles.catalogs}>
        <div className={styles.header}>KEY</div>
        {catalogs}
      </div>
    </>
  );
}

function Catalogs(props) {
  const {
    spinitronTop50ShelfIndex,
    labelRecordsShelfIndex,
    uiPhase,
    recentsShelf,
    activeCatalogsMap
  } = props;
  if (
    uiPhase !== SEARCH_UI_PHASE_RECOMMENDED &&
    uiPhase !== SEARCH_UI_PHASE_NEW_RELEASES
  )
    return null;
  //if (recentsShelf.records.length === 0) return null;

  return (
    <div className={styles.container}>
      <CatalogList {...props} />
      <Shelf
        showDeleted={true}
        showSortOptions={false}
        defaultSortOption={"releaseDate"}
        recordClass="catalogNew"
        groupRecordClass="search-results"
        groupContainerStyle={{
          display: "grid",
          gridGap: "12px",
          overflowX: "scroll",
          scrollSnapType: "x proximity",
          gridTemplateColumns: "repeat(6, 250px)"
        }}
        recordProps={{ playOnHover: true }}
        index={labelRecordsShelfIndex}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    catalogActiveMap: state.catalogs.catalogActiveMap,
    allCatalogs: state.catalogs.allCatalogs,
    loggedIn: state.authentication.loggedIn,
    uiPhase: state.search.uiPhase,
    palette: state.ui.palette,
    spinitronTop50ShelfIndex: state.record.spinitronTop50ShelfIndex,
    labelRecordsShelfIndex: state.record.labelRecordsShelfIndex,
    appleMusicNewReleasesShelfIndex:
      state.record.appleMusicNewReleasesShelfIndex,
    recentsShelf: state.record.shelves[state.record.recentsShelfIndex],
    spinitronTop50Unloaded:
      state.record.externalSourceLoadState[EXTERNAL_SOURCE_SPINITRON_TOP_50] ===
      UNLOADED,
    labelRecordsLoadingState:
      state.record.externalSourceLoadState[EXTERNAL_SOURCE_LABEL_RECORDS]
  };
};

const mapDispatchToProps = (dispatch, getState) => {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Catalogs);
