import React, { useState, useEffect } from "react";
import styles from "./player.module.scss";

import connect from "react-redux/es/connect/connect";
import {
  playPause,
  stop,
  next,
  prev,
  toggleOverPlayer,
  recordSelected,
  recordPlayPressed
} from "../../actions/playback-actions";
import {
  areRecordsTheSame,
  isPlaylist,
  jlog,
  randomizeAttributes
} from "../../helpers";
import _ from "lodash";
import moment from "moment";
import {
  loadDiscographyForRecord,
  loadDetailedInfoForRecord,
  loadRecordIfNeeded,
  removeRecordFromRecordStoryMap,
  setSelectedRecord,
  clearSelectedRecord
} from "../../actions/record-actions";
import { flag, Flags } from "../../helpers/flags";
import { TouchableOpacity } from "react-native-web";
import { isContainedInIosNativeApp } from "../../helpers/native-ios-mk-implementation";
import { TURN_BIG_RECORD_TO_SIDE } from "../../reducers/record-reducer";
import { SELECTION_ANIMATION_DURATION } from "../SelectionAnimationOverlay/selection-animation-overlay";
import { setPaletteBasedOnRecord } from "../../actions/ui-actions";

function Player(props) {
  const {
    playbackStateName,
    palette,
    playingRecord,
    playingTrack,
    playing,
    prev,
    next,
    stop,
    playPause,
    artistName,
    currentTrackNumber,
    numTracks,
    currentPlaybackTime,
    klass = "fixed",
    albumLength,
    visibleButtons,
    selectedRecord,
    pickedItem,
    currentlyPlayingRecord
  } = props;

  const [playbackInitiating, setPlaybackInitiating] = useState(false);

  useEffect(() => {
    if (playbackStateName === "playing" && playbackInitiating)
      setPlaybackInitiating(false);
  }, [playbackStateName]);

  function expandDismissTapped() {
    if (playingRecord)
      props.toggleSelectedRecord(playingRecord, selectedRecord);
    else {
      props.dismiss();
    }
  }
  const isAPlaylist = isPlaylist(playingRecord);

  let playStyle = visibleButtons["play"] ? {} : { display: "none" };
  const stopStyle = visibleButtons["stop"] ? {} : { display: "none" };
  const pauseStyle = visibleButtons["pause"] ? {} : { display: "none" };
  const prevStyle = visibleButtons["prev"] ? {} : { display: "none" };
  const nextStyle = visibleButtons["next"] ? {} : { display: "none" };
  playStyle =
    isAPlaylist && !props.tracksLoaded ? { display: "none" } : playStyle;

  const duration = moment.duration(currentPlaybackTime, "seconds");
  const formattedPlaybackTime = moment
    .utc(duration.as("milliseconds"))
    .format("m:ss");

  const opacity = 0.97;
  let a = {};
  if (playingRecord) {
    a = _.get(playingRecord, "attributes", {});
  } else if (playingTrack) {
    a = _.get(playingTrack, "attributes", {});
  }

  const containerStyle = {
    color: palette.textColor,
    //backgroundColor: palette.backgroundColor,
    paddingTop:
      isContainedInIosNativeApp() && props.clientHeight >= 812 ? 29 : 0
  };

  const year = moment(a.releaseDate, "YYYY-MM-DD").format("YYYY");
  const openHeight =
    isContainedInIosNativeApp() && props.clientHeight >= 812 ? 91 : 72;
  const showPlayer = playingRecord || selectedRecord || playingTrack;
  const disappearTransform = `translateY(${openHeight}px)`;

  const transform = showPlayer ? null : disappearTransform;
  let trackNumString = "";
  if (
    playingRecord &&
    currentTrackNumber !== null &&
    currentTrackNumber !== undefined &&
    numTracks
  )
    trackNumString = `${currentTrackNumber}/${numTracks}`;
  const controlsStyle = {
    ...props.controlsStyle,
    opacity: playbackInitiating ? 0.2 : 1
  };
  const rotate = selectedRecord ? "0deg" : "-180deg";
  const iconStyle = { transform: `rotate(${rotate})` };
  if (!(playingRecord || selectedRecord || playingTrack)) return null;
  return (
    <div id="player" className={styles[klass]}>
      <TouchableOpacity
        style={{
          display: "flex",
          width: "100%",
          ...{ opacity },
          height: openHeight,
          transform,
          overflowY: "hidden"
        }}
        onPress={() =>
          props.toggleSelectedRecord(playingRecord, selectedRecord)
        }
      >
        {(playingRecord || selectedRecord || playingTrack) && (
          <div
            className={styles.container}
            style={{
              height: openHeight - 8
            }}
          >
            <div className={styles.dismiss}>
              <TouchableOpacity
                hitSlop={{ top: 10, left: 350, bottom: 150, right: 10 }}
                onPress={expandDismissTapped}
                style={{
                  display: "flex",
                  //paddingLeft: 150,
                  // paddingBottom: 150,
                  justifyContent: "center"
                }}
                // style={{ transform: "translate(100,-14)" }}
              >
                {/*<div className={styles.hitSlop}>*/}
                <div className={styles.circle}>
                  <div className={styles.icon} style={iconStyle}>
                    <i className="fa fa-chevron-down" />
                  </div>
                </div>
                {/*</div>*/}
              </TouchableOpacity>
            </div>

            <div className={styles.artistName}>{artistName}</div>
            <div className={styles.recordName}>
              {(playingRecord || selectedRecord) && props.albumName
                ? `${props.albumName} ${
                    albumLength && !isAPlaylist ? `(${albumLength}m)` : ""
                  }`
                : null}{" "}
              {playingTrack && a.albumName}
            </div>
            <div className={styles.labelName}>{props.labelName}</div>
            <div className={styles.songName}>{props.songName}</div>
            <div className={styles.trackInfo}>
              <div className={styles.trackNumber}>{trackNumString}</div>
              {flag(Flags.updateProgress) && (
                <div className={styles.elapsedSeconds}>
                  {formattedPlaybackTime}
                </div>
              )}
            </div>
            <div className={styles.controls} style={controlsStyle}>
              <div className={styles.prev} style={prevStyle}>
                <TouchableOpacity
                  onPress={e => {
                    setPlaybackInitiating(true);
                    prev(e);
                  }}
                >
                  <i className="fa fa-backward" />
                </TouchableOpacity>
              </div>
              <div className={styles.stop} style={stopStyle}>
                <TouchableOpacity
                  onPress={e => {
                    stop(e);
                  }}
                >
                  <i className="fa fa-stop" />
                </TouchableOpacity>
              </div>
              <div className={styles.play}>
                <TouchableOpacity
                  onPress={e => {
                    playbackStateName !== "playing" &&
                      setPlaybackInitiating(true);
                    playPause(
                      e,
                      selectedRecord || currentlyPlayingRecord,
                      props.mediaItem
                    );
                  }}
                >
                  <i className="fa fa-pause" style={pauseStyle} />
                  <i className="fa fa-play" style={playStyle} />
                </TouchableOpacity>
              </div>
              <div className={styles.next} style={nextStyle}>
                <TouchableOpacity
                  onPress={e => {
                    setPlaybackInitiating(true);
                    next(e);
                  }}
                >
                  <i className="fa fa-forward" />
                </TouchableOpacity>
              </div>
            </div>
          </div>
        )}
      </TouchableOpacity>
    </div>
  );
}

function visiblePlayControlButtons(playing, queueLength, queuePosition) {
  const ret = { stop: true };
  if (playing) {
    ret["pause"] = true;
  } else {
    ret["play"] = true;
  }
  const initialPosition = isContainedInIosNativeApp() ? 1 : 0;
  if (queueLength > 1) {
    if (playing) {
      if (queuePosition > initialPosition) ret["prev"] = true;
      if (queuePosition < queueLength) ret["next"] = true;
    }
  }
  return ret;
}

const mapStateToProps = (state, ownProps) => {
  const PlaybackStates = _.get(window, "MusicKit.PlaybackStates");
  const playing =
    state.playback.playbackState ===
      (PlaybackStates && PlaybackStates["playing"]) ||
    state.playback.nativePlaybackState === 1;

  let record = state.playback.currentlyPlayingRecord;
  record =
    record && flag(Flags.useRandomRecordData)
      ? randomizeAttributes(record)
      : record;
  const selectedRecord = _.get(state, "lightweightRecord.selected.record");
  const playingRecord =
    state.playback.currentlyPlayingRecord ||
    state.playback.currentlyPlayingTrack;
  const selectedRecordIsPlaying =
    selectedRecord &&
    (areRecordsTheSame(selectedRecord, state.playback.mediaItem) ||
      areRecordsTheSame(selectedRecord, playingRecord));
  let numTracks,
    currentTrackNumber,
    songName,
    artistName,
    albumName,
    labelName,
    visibleButtons,
    albumLength,
    controlsStyle;
  if (selectedRecord && !selectedRecordIsPlaying) {
    visibleButtons = { play: true };
    artistName = selectedRecord.attributes.artistName;
    albumName = selectedRecord.attributes.name;
    labelName = selectedRecord.attributes.recordLabel;
    controlsStyle = { gridRow: "1/4", fontSize: 63 };
  } else {
    numTracks =
      record && isPlaylist(record)
        ? (state.record.recordIdToTracksMap[record.id] || []).length
        : _.get(state.playback, "queue._itemIDs", []).length;
    currentTrackNumber = parseInt(_.get(state.playback, "queue._position")) + 1;

    visibleButtons = visiblePlayControlButtons(
      playing,
      numTracks,
      currentTrackNumber
    );

    songName = _.get(state.playback, "mediaItem.attributes.name");
    artistName = _.get(state.playback, "mediaItem.attributes.artistName");
    albumName = _.get(state.playback, "mediaItem.attributes.albumName");
    albumLength = Math.floor(
      _.get(state.playback, "queue._itemIDs", []).reduce((acc, item) => {
        const millis = _.get(item, "attributes.durationInMillis");
        return millis ? acc + parseInt(millis) / 1000 : acc;
      }, 0) / 60
    );
  }

  return {
    selectedRecordIsPlaying,
    bigRecordSide: state.lightweightRecord.bigRecordSide,
    albumLength,
    clientHeight: state.ui.documentElement.clientHeight,
    clientWidth: state.ui.documentElement.clientWidth,
    record,
    albumName,
    labelName,
    controlsStyle,
    recordIdToLabelRecordsMap: state.record.recordIdToLabelRecordsMap,
    recordSlugsWithNoImages: state.record.recordSlugsWithNoImages,
    overPlayerInDom: state.playback.overPlayerInDom,
    overPlayerSlidUp: state.playback.overPlayerSlidUp,
    palette: state.ui.palette,
    mediaItem: state.playback.mediaItem,
    progress: state.playback.progress,
    playingRecord,
    playingTrack: state.playback.currentlyPlayingTrack,
    selectedRecord,
    playing,
    numTracks,
    currentTrackNumber,
    visibleButtons,
    songName,
    artistName,
    playbackState: state.playback.playbackState,
    playbackStateName: state.playback.playbackStateName,
    currentlyPlayingRecord: state.playback.currentlyPlayingRecord,
    currentPlaybackDuration: state.playback.currentPlaybackDuration,
    currentPlaybackTime: state.playback.currentPlaybackTime,
    currentPlaybackTimeRemaining: state.playback.currentPlaybackTimeRemaining,
    pickedItem: state.ui.pickedItem,
    tracksLoaded:
      (record || selectedRecord) &&
      state.record.recordIdToTracksMap[(record || selectedRecord).id]
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleSelectedRecord: (record, selectedRecord, layoutRect) => {
      const element = document.getElementById("player");
      selectedRecord
        ? dispatch(clearSelectedRecord())
        : dispatch(recordSelected(record, element.getBoundingClientRect()));
    },
    recordSelected: (record, layoutRect) => {
      dispatch(loadRecordIfNeeded(record, true));
      dispatch(recordSelected(record, layoutRect));
    },
    removeFromRecordStoryMap: (keyRecord, record) =>
      dispatch(removeRecordFromRecordStoryMap(keyRecord, record)),
    prev: e => {
      e.stopPropagation();
      dispatch(prev());
    },
    next: e => {
      e.stopPropagation();
      dispatch(next());
    },
    stop: e => {
      e.stopPropagation();
      dispatch(clearSelectedRecord());
      dispatch(stop());
    },
    playPause: (e, record, mediaItem) => {
      e.stopPropagation();
      if (areRecordsTheSame(record, mediaItem)) {
        dispatch(playPause(record));
      } else dispatch(recordPlayPressed(record, false));
    },
    loadLabelRecordsForRecord: (record, num) =>
      dispatch(loadDetailedInfoForRecord(record, num)),
    loadDiscographyForRecord: (record, num) =>
      dispatch(loadDiscographyForRecord(record, num)),
    dismiss: () => {
      dispatch(clearSelectedRecord());
      setTimeout(
        () => dispatch({ type: TURN_BIG_RECORD_TO_SIDE, side: 0 }),
        SELECTION_ANIMATION_DURATION
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Player);
