import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Record from "../../components/Record/record";
import { connect } from "react-redux";
import { setPaletteBasedOnRecord } from "../../actions/ui-actions";
import styles from "./record-page.module.scss";
import { cleanName, slugForRecord } from "../../helpers";
import SelectedRecord from "../../components/SelectedRecord/selected-record";
import {
  loadRecordIfNeeded,
  setSelectedRecord
} from "../../actions/record-actions";
import Player from "../../components/Player/player";
import SafeArea from "react-safe-area-component";

function RecordPageComponent(props) {
  let { id } = useParams();

  useEffect(() => {
    props.loadRecordIfNeeded({ id }, true, _record => {
      props.setPaletteBasedOnRecord(_record);
      props.setSelectedRecord(_record);
    });
  }, [id]);

  return (
    <>
      <div
        className={styles.container}
        style={{ minHeight: props.clientHeight }}
      >
        <SelectedRecord />
        <Player />
      </div>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    clientHeight: state.ui.documentElement.clientHeight,
    clientWidth: state.ui.documentElement.clientWidth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setPaletteBasedOnRecord: id => dispatch(setPaletteBasedOnRecord(id)),
    setSelectedRecord: record => dispatch(setSelectedRecord(record)),
    loadRecordIfNeeded: (record, forceLoad, onLoad) => {
      dispatch(loadRecordIfNeeded(record, forceLoad, onLoad));
    }
  };
};

const RecordPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordPageComponent);

export default RecordPage;
