import _ from "lodash";
import { store } from "../index";
import {
  MEDIA_ITEM_CHANGED,
  NATIVE_MEDIA_ITEM_CHANGED,
  SET_NATIVE_PLAYBACK_STATE
} from "../reducers/playback-reducer";
import { receivedNativeMediaItemChanged } from "../actions/playback-actions";
import { jlog } from "../helpers";
import { dlog } from "../actions/debug-actions";

export function isContainedInIosNativeApp() {
  return !!_.get(window, "webkit.messageHandlers.doStuffMessageHandler");
}

function sendMessageToNative(message, data) {
  const handler = _.get(window, "webkit.messageHandlers.doStuffMessageHandler");
  jlog({ sendMessageToNative: 1 });
  if (!handler) return;
  jlog({ message, data });
  handler.postMessage({
    param1: message,
    param2: data
  });
}

window.setPlaybackState = playbackState => {
  window.alert("setPlaybackState");

  console.log("received playbackstate from native", playbackState);
  store.dispatch({ type: SET_NATIVE_PLAYBACK_STATE, playbackState });
};

window.nativeMediaItemChanged = event => {
  console.log("received nativeMediaItemChanged from native", event);
  store.dispatch(receivedNativeMediaItemChanged(event));
};

class NativeIosMkPlayerImplementation {
  static instance = null;
  static playbackState = "playbackStateNotSet";

  static getInstance() {
    if (!NativeIosMkPlayerImplementation.instance) {
      NativeIosMkPlayerImplementation.instance = new NativeIosMkPlayerImplementation();
    }
    return NativeIosMkPlayerImplementation.instance;
  }

  addEventListener = (eventName, callback) => {
    return null;
  };

  removeEventListener = (eventName, callback) => {
    return null;
  };

  play = () => {
    window.alert("play");
    sendMessageToNative("play");
    return Promise.resolve();
  };

  pause = () => {
    sendMessageToNative("pause");
    return Promise.resolve();
  };

  stop = () => {
    sendMessageToNative("stop");
    return Promise.resolve();
  };

  skipToNextItem = () => {
    sendMessageToNative("skipToNextItem");
    return Promise.resolve();
  };

  skipToPreviousItem = () => {
    sendMessageToNative("skipToPreviousItem");
    return Promise.resolve();
  };

  /*
    const numTracks = _.get(
    state.playback,
    "queue._items",[]
  ).length;
  const currentTrackNumber = _.get(state.playback, "queue._position") + 1;
  const songName = _.get(state.playback, "mediaItem.attributes.name");
  const artistName = _.get(state.playback, "mediaItem.attributes.artistName")
  const albumName = _.get(state.playback, "mediaItem.attributes.albumName")
   */

  setQueue = mediaItem => {
    if (mediaItem === {}) {
      this.queue = { kind: "empty" };

      return;
    }

    let kind = null;
    if (mediaItem["album"]) {
      kind = "album";
    } else if (mediaItem.song) {
      kind = "song";
    } else if (mediaItem.playlist) {
      kind = "playlist";
    }
    if (kind === null) {
      this.queue = { kind: "n/a" };
      return;
    }

    mediaItem.kind = kind;

    const id = mediaItem[kind];

    const ids = Array.isArray(id) ? id : [id];

    this.queue = {
      kind,
      ids
    };
    sendMessageToNative("setQueue", this.queue);
  };

  changeToMediaAtIndex = index => {
    this.index = index;
    sendMessageToNative("changeToMediaAtIndex", index);
  };
}

export default class NativeIosMkImplementation {
  static instance = null;
  static player = null;
  static api = null;

  static getInstance() {
    if (!NativeIosMkImplementation.instance) {
      NativeIosMkImplementation.instance = new NativeIosMkImplementation();
    }
    return NativeIosMkImplementation.instance;
  }

  constructor() {
    NativeIosMkImplementation.player = NativeIosMkPlayerImplementation.getInstance();
    NativeIosMkImplementation.api = window.MusicKit.getInstance();
  }

  authorize = () => {
    return Promise.resolve();
  };

  setQueue = mediaItem => {
    NativeIosMkImplementation.player.setQueue(mediaItem);
    return Promise.resolve();
  };

  changeToMediaAtIndex = index => {
    NativeIosMkImplementation.player.changeToMediaAtIndex(index);
    return Promise.resolve();
  };
}
