import {
  PLAYBACK_STARTED,
  PLAYBACK_STOPPED,
  PLAYBACK_PAUSED,
  MEDIA_ITEM_CHANGED,
  PLAYBACK_PROGRESS_CHANGED,
  PLAYBACK_TIME_CHANGED,
  PLAYBACK_STATE_CHANGED,
  TRANSITION_PREVIEW_TO_PLAY,
  PREVIEW_STARTED,
  PREVIEW_STOPPED,
  CLEAR_PLAYBACK,
  NATIVE_MEDIA_ITEM_CHANGED,
  SET_CURRENTLY_PLAYING_RECORD
} from "../reducers/playback-reducer";
import {
  areRecordsTheSame,
  jlog,
  debounce,
  mk,
  mkplayer,
  isPlaylist
} from "../helpers";
import * as Vibrant from "node-vibrant";
import _ from "lodash";
import {
  setNormalPalette,
  setPaletteBasedOnImageUrl,
  setPaletteBasedOnRecord
} from "./ui-actions";
import {
  addRecordsToMap,
  setSelectedRecord,
  catalogIdForRecord,
  addUpdateRecordViaOrbit
} from "./record-actions";
import { EndPoints, flag, Flags } from "../helpers/flags";
import { store } from "../index";
import { isContainedInIosNativeApp } from "../helpers/native-ios-mk-implementation";
import { flash } from "./ui-actions";
import { reportEvent } from "../analytics";

function mediaItemChanged(mediaItem) {
  return function(dispatch, getState) {
    const { currentlyPlayingRecord } = getState().playback;
    const {
      recentsShelfIndex,
      recentlyPlayedShelfIndex,
      recently
    } = getState().record;

    dispatch({ type: MEDIA_ITEM_CHANGED, mediaItem });
    const imageUrl = _.get(mediaItem, "attributes.artwork.url");
    if (currentlyPlayingRecord) {
      dispatch({
        type: "SELECT_COVER_ART",
        record: getState().playback.currentlyPlayingRecord,
        image: imageUrl
      });
      dispatch(setPaletteBasedOnImageUrl(imageUrl));
    }
    const songName = _.get(mediaItem, "attributes.name");
    const artistName = _.get(mediaItem, "attributes.artistName");
    const albumName = _.get(mediaItem, "attributes.albumName");

    jlog({ mediaItem });
    dispatch(flash(`"${songName}" by ${artistName} `, "playback"));

    if (currentlyPlayingRecord) {
      addUpdateRecordViaOrbit(
        currentlyPlayingRecord,
        currentlyPlayingRecord.attributes.source || EndPoints.recentlyPlayed,
        true,
        true
      );
    }
    // if (currentlyPlayingRecord && _.get(event, "item.trackNumber", null) > 1) {
    //   debounce(
    //     "persist",
    //     () => {
    //       dispatch(
    //         insertRecordIntoTopOfShelf(
    //           recentsShelfIndex,
    //           currentlyPlayingRecord
    //         )
    //       );
    //       dispatch(
    //         insertRecordIntoTopOfShelf(
    //           recentlyPlayedShelfIndex,
    //           currentlyPlayingRecord
    //         )
    //       );
    //
    //       //dispatch(conditionallyPersistPlayback(currentlyPlayingRecord));
    //     },
    //     1000
    //   );
    // }
  };
}

export function receivedNativeMediaItemChanged(_event) {
  /* used:
    queue:
      _position
      _items

    mediaItem:
      attributes.name
      attributes.artistName
      attributes.albumName
   */
  return function(dispatch, getState) {
    console.log(_event);
    let event;
    try {
      event = JSON.parse(_event);
    } catch (error) {}
    console.log(receivedNativeMediaItemChanged, event);
    const { currentlyPlayingRecord } = getState().playback;

    // const record = getState().record.slugToRecordMap[
    //   slugForArtistNameAndAlbumName(event.artist, event.album)
    // ];
    // jlog({ record });
    // if (currentlyPlayingRecord && event.indexOfNowPlayingItem === 2) {
    //   dispatch(conditionallyPersistPlayback(currentlyPlayingRecord));
    // }
    //if (!record) return;
    console.log(event);
    let queue = {
      _position: event.indexOfNowPlayingItem,
      _itemIDs: currentlyPlayingRecord
        ? new Array(currentlyPlayingRecord.attributes.trackCount)
        : []
    };
    /*
      "event": {
    "album": "Cloud Nine",
    "albumTrackNumber": "1",
    "artist": "The Temptations",
    "indexOfNowPlayingItem": "0",
    "song": "Cloud Nine"
    }

    "{\"song\":\"Nobody\'s Empire\",
    \"indexOfNowPlayingItem\":\"0\",
    \"artist\":\"Belle and Sebastian\",
    \"album\":\"Girls in Peacetime Want to Dance\",
    \"albumTrackNumber\":\"1\"}"
// jsonString	String	"{\"albumTrackNumber\":\"1\",\"artist\":\"Run The Jewels\",\"album\":\"RTJ4\",\"song\":\"yankee and the brave (ep. 4)\",\"indexOfNowPlayingItem\":\"0\"}"
     */
    jlog({ queue });
    let mediaItem = {
      attributes: {
        name: event.song,
        artistName: event.artist,
        albumName: event.album
      },
      _container: {
        attributes: {
          name: event.song,
          artistName: event.artist,
          albumName: event.album
        }
      }
    };
    jlog({ mediaItem });
    dispatch({ type: NATIVE_MEDIA_ITEM_CHANGED, queue, mediaItem });
  };
}

function onMediaItemDidChange(mediaItem) {
  store.dispatch(mediaItemChanged(mediaItem));
}
//
// export function conditionallyPersistPlayback(record) {
//   return function(dispatch, getState) {
//     const { recentsShelfIndex } = getState().record;
//     // dispatch(addPlayedRecordIdToShelf(recentsShelfIndex, record.id));
//     dispatch(conditionallyAddRecordToRecentlyPlayedPlaylist(record));
//   };
// }

function onPlaybackProgressDidChange(event) {
  store.dispatch({ type: PLAYBACK_PROGRESS_CHANGED, event });
}

function onPlaybackTimeDidChange(event) {
  store.dispatch({ type: PLAYBACK_TIME_CHANGED, event });
}

function onPlaybackStateDidChange(event) {
  console.log("onPlaybackStateDidChange", event);
  const playbackState = mkplayer().playbackState;
  store.dispatch({
    type: PLAYBACK_STATE_CHANGED,
    playbackState
  });
}

let eventInterval;

// setInterval(() => {
//   jlog({ nowPlayingItem: mkplayer().nowPlayingItem });
//   jlog({ nowPlayingItemIndex: mkplayer().nowPlaxyingItemIndex });
// }, 1000);

export function setupEventListening() {
  return function(dispatch, getState) {
    if (isContainedInIosNativeApp()) return;
    // eventInterval = setInterval(() => {
    //   if (getState().playback.playbackStateName === "stopped") {
    //     console.log("stopping ...");
    //     dispatch(stop());
    //   }
    // }, 1000);
    console.log("setting up event listeners ...");
    mkplayer().addEventListener(
      "mediaItemStateDidChange",
      onMediaItemDidChange
    );
    // flag(Flags.updateProgress) &&
    //   mkplayer().addEventListener(
    //     window.MusicKit.Events.playbackProgressDidChange,
    //     onPlaybackProgressDidChange
    //   );
    // flag(Flags.updateProgress) &&
    //   mkplayer().addEventListener(
    //     window.MusicKit.Events.playbackTimeDidChange,
    //     onPlaybackTimeDidChange
    //   );
    mkplayer().addEventListener(
      window.MusicKit.Events.playbackStateDidChange,
      onPlaybackStateDidChange
    );
  };
}

function removeEventListeners() {
  if (isContainedInIosNativeApp()) return;
  console.log("removing event listeners ...");
  eventInterval && clearInterval(eventInterval);
  mkplayer().removeEventListener(
    window.MusicKit.Events.mediaItemDidChange,
    onMediaItemDidChange
  );
  // flag(Flags.updateProgress) &&
  //   mkplayer().removeEventListener(
  //     window.MusicKit.Events.playbackProgressDidChange,
  //     onPlaybackProgressDidChange
  //   );
  flag(Flags.updateProgress) &&
    mkplayer().removeEventListener(
      window.MusicKit.Events.playbackTimeDidChange,
      onPlaybackTimeDidChange
    );
  mkplayer().removeEventListener(
    window.MusicKit.Events.playbackStateDidChange,
    onPlaybackStateDidChange
  );
}

//
//
// const play = mediaItem => {
//   if (mediaItem !== undefined) {
//     this.musicKit.setQueue({ song: mediaItem.id }).then(queue => {
//       this.musicKit.play();
//     });
//   } else {
//     this.musicKit.play();
//   }
// };
//

export function next() {
  return function(dispatch, getState) {
    const state = getState();
    mkplayer().skipToNextItem();
  };
}

export function prev() {
  return function(dispatch, getState) {
    const state = getState();

    mkplayer().skipToPreviousItem();
  };
}

export function playPause(record) {
  return function(dispatch, getState) {
    const state = getState();
    const { playbackState, nativePlaybackState } = state.playback;
    const { PlaybackStates } = window.MusicKit;
    const isThisRecordAlreadyPlayingOrPaused = areRecordsTheSame(
      record,
      _.get(state.playback, "mediaItem")
    );
    if (
      (!playbackState && !nativePlaybackState) ||
      !isThisRecordAlreadyPlayingOrPaused
    ) {
      dispatch(flash("calling startPlayingRecord ...", "playback"));

      dispatch(startPlayingRecord(record));
      dispatch(flash("startPlayingRecord called ...", "playback"));
    } else {
      if (
        playbackState === PlaybackStates.playing ||
        nativePlaybackState === 1
      ) {
        dispatch(flash("requesting pause ...", "playback"));
        mkplayer().pause();
        dispatch(flash("pause requested ...", "playback"));
      } else {
        dispatch(flash("requesting pause ...", "playback"));
        mkplayer().pause();
        dispatch(flash("pause requested ...", "playback"));
        dispatch(flash("requesting playback ...", "playback"));
        mkplayer().play();
        dispatch(flash("playback requested ...", "playback"));
      }
    }
  };
}

export function pausePlaying() {
  return function(dispatch, getState) {
    const state = getState();
    mkplayer().pause();
    dispatch({ type: PLAYBACK_PAUSED });
  };
}

export function stop() {
  return function(dispatch, getState) {
    const state = getState();
    dispatch(setNormalPalette());
    mkplayer().stop();
    mk().setQueue({});
    dispatch({ type: MEDIA_ITEM_CHANGED, event: { item: null } });
    dispatch({ type: PLAYBACK_STOPPED });
    dispatch({ type: CLEAR_PLAYBACK });
    removeEventListeners();
  };
}

export function startPlayingRecord(record) {
  return async function(dispatch, getState) {
    const state = getState();
    if (!record) {
      window.alert("record is null");
      return;
    }
    const id = isPlaylist(record)
      ? record.id
      : await catalogIdForRecord(record, state);
    record.id = id;
    reportEvent(
      "record played",
      `${record.attributes.name} - ${record.attributes.artistName}`
    );
    if (flag(Flags.actuallyPlayMusic)) {
      dispatch(setupEventListening());
      // try {
      // mk()
      //   .authorize()
      //   .then(function() {
      dispatch(flash("beginning playback sequence ...", "playback"));
      mk()
        .setQueue({
          album: record.id,
          _position: 0,
          _items: isPlaylist(record)
            ? null
            : new Array(record.attributes.trackCount)
        })
        .then(() => {
          dispatch(flash("queue set ...", "playback"));

          mkplayer().play();
          dispatch(flash("playback requested ...", "playback"));
        })
        .catch(err => {
          reportEvent(
            "record play failed",
            `${record.attributes.name} - ${record.attributes.artistName}`
          );
          console.log(`playback error (${err})`);
          dispatch(
            flash(
              `Couldn't play this track.  ${
                err ? `(${err})` : "(reason unknown)"
              }`,
              "error"
            )
          );
        });
      //      });
      // } catch (err) {
      //   console.log("playback error");
      // }
    }

    dispatch({ type: PLAYBACK_STARTED, record });
  };
}

export function setIsMouseOverPreviewingRecord(bool, x, y) {
  return { type: "setIsMouseOverPreviewingRecord", bool, x, y };
}

export function startPreviewingRecord(record, layoutRect) {
  return async function(dispatch, getState) {
    // const { playback } = getState();
    //
    // if (
    //   playback.currentlyPlayingRecord ||
    //   playback.currentlyPlayingTrack ||
    //   playback.mediaItem
    // ) {
    //   return;
    // }
    if (!mk()) return;

    reportEvent(
      "record previewed",
      `${record.attributes.name} - ${record.attributes.artistName}`
    );
    dispatch({ type: CLEAR_PLAYBACK });
    //dispatch({ type: UPDATE_PREVIEW_STATUS, status: "cueing" });

    const id = await catalogIdForRecord(record, getState());
    // dispatch(setupEventListening());
    try {
      await mkplayer().stop();
    } catch (err) {
      dispatch(
        flash(`Preview didn't work (stop) - try again in a bit. (${err})`)
      );
    }
    try {
      await mk().setQueue({ album: id });
    } catch (err) {
      dispatch(
        flash(`Preview didn't work (setQueue) - try again in a bit. (${err})`)
      );
    }

    if (flag(Flags.actuallyPlayMusic)) {
      try {
        await mkplayer().play();
      } catch (err) {
        dispatch(
          flash(`Preview didn't work (play) - try again in a bit. (${err})`)
        );
      }
      const state = getState();
      if (state.ui.hoverElementId === record.id) {
        dispatch(setPaletteBasedOnRecord(record));

        dispatch({ type: PREVIEW_STARTED, record });

        removeEventListeners();

        dispatch(setupEventListening());
      } else {
        dispatch(stopPreviewingRecord(record));
      }
    }
  };
}

export function stopPreviewingRecord(record, force) {
  return function(dispatch, getState) {
    if (
      getState().playback.currentlyPlayingRecord ||
      getState().playback.currentlyPlayingTrack
    )
      return;
    const currentlyPreviewingRecord = getState().playback
      .currentlyPreviewingRecord;
    if (!record && !force) return;
    if (currentlyPreviewingRecord) dispatch(setNormalPalette());

    // if (
    //   currentlyPreviewingRecord == null ||
    //   record == null ||
    //   areRecordsTheSame(currentlyPreviewingRecord, record) ||
    //   force
    // ) {
    //   // jlog({ clearingQueue: timeout });
    //   //mk().setQueue({});
    //   mkplayer().stop();
    //   dispatch({ type: UPDATE_PREVIEW_STATUS, status: "" });
    //   dispatch({ type: PREVIEW_STOPPED, record });
    // }
    // dispatch({ type: UPDATE_PREVIEW_STATUS, status: null });
    //
    // dispatch({ type: PREVIEW_STOPPED, record });
    //mk().setQueue({});
    for (let timeout of [0]) {
      setTimeout(() => {
        // const currentlyPreviewingRecord = getState().playback
        //   .currentlyPreviewingRecord;
        record && dispatch({ type: PREVIEW_STOPPED, record });

        if (
          true
          // currentlyPreviewingRecord == null ||
          // record == null ||
          // areRecordsTheSame(currentlyPreviewingRecord, record) ||
          // force
          // record &&
          // _.get(getState(), "playback.mediaItem._container.id") === record.id
        ) {
          //mk().setQueue({});

          let player = mkplayer();
          player &&
            player
              .stop()
              .then(() => {
                //dispatch({ type: UPDATE_PREVIEW_STATUS, status: "" });
              })
              .catch(err => {});
        }
      }, timeout);
    }
    // mkplayer().stop();
  };
}

export function transitionPreviewToPlay(layoutRect = null) {
  return function(dispatch, getState) {
    const previewingRecord = getState().playback.currentlyPreviewingRecord;
    //dispatch( setOverPlayerActive(true) );
    //dispatch(recordSelected(previewingRecord, layoutRect));

    setTimeout(() => {
      //dispatch(scrollYTo(0));
    }, 0);
    dispatch(setupEventListening());
    //dispatch(clearSearchUIPhase( ));

    dispatch({ type: TRANSITION_PREVIEW_TO_PLAY });
  };
}

export function startPlayingPlaylist(record) {
  return async function(dispatch, getState) {
    const state = getState();
    const { currentlyPlayingRecord } = state.playback;
    const id = isPlaylist(record)
      ? record.id
      : await catalogIdForRecord(record, state);

    //dispatch(scrollYTo(0));

    const trackIds =
      state.record.recordIdToTracksMap[record.id] &&
      state.record.recordIdToTracksMap[record.id]
        .map(track => _.get(track, "attributes.playParams.catalogId"))
        .filter(t => !!t);
    const queue = {
      playlist: isContainedInIosNativeApp() ? trackIds : record.id,
      _position: 0,
      _items: 0
    };

    reportEvent(
      "playlist played",
      `${record.attributes.name} - ${record.attributes.artistName}`
    );
    if (flag(Flags.actuallyPlayMusic)) {
      dispatch(setupEventListening());
      try {
        mk()
          .authorize()
          .then(function() {
            mk()
              .setQueue(queue)
              .then(() => {
                try {
                  try {
                    mkplayer().play();
                  } catch (err) {
                    reportEvent(
                      "playlist play failed (1)",
                      `${record.attributes.name} - ${record.attributes.artistName}`
                    );
                    dispatch(flash(`Playback error! ${err}`, "error"));
                  }
                } catch (err) {
                  reportEvent(
                    "playlist play failed (2)",
                    `${record.attributes.name} - ${record.attributes.artistName}`
                  );
                  dispatch(flash(`Playback error! ${err}`, "error"));
                }
              })
              .catch(err => {
                reportEvent(
                  "playlist play failed (3)",
                  `${record.attributes.name} - ${record.attributes.artistName}`
                );
                console.log(`playback error (${err})`);
              });
          });
      } catch (err) {
        reportEvent(
          "playlist play failed (4)",
          `${record.attributes.name} - ${record.attributes.artistName}`
        );
        console.log("playback error");
      }
    }

    dispatch({ type: CLEAR_PLAYBACK });
    dispatch({ type: PLAYBACK_STARTED, record, player: mkplayer() });
  };
}

export function startPlayingRecordComplicated(record) {
  return async function(dispatch, getState) {
    const state = getState();
    const { currentlyPlayingRecord } = state.playback;
    const id = isPlaylist(record)
      ? record.id
      : await catalogIdForRecord(record, state);

    record.id = id;
    record.catalogAlbumId = id;
    dispatch(addRecordsToMap([record]));
    dispatch({ type: CLEAR_PLAYBACK });
    dispatch({ type: SET_CURRENTLY_PLAYING_RECORD, record });
    if (flag(Flags.actuallyPlayMusic)) {
      // otherwise it blocks the ui update
      //dispatch(setupEventListening());
      try {
        mk()
          .setQueue({
            album: id,
            _position: 0,
            _items: isPlaylist(record)
              ? null
              : new Array(record.attributes.trackCount)
          })
          .then(() => {
            try {
              mkplayer().play();
            } catch (err) {
              dispatch(flash(`Playback error! ${err}`, "error"));
            }
          })
          .catch(err => {
            console.log(`playback error (${err})`);
          });
      } catch (err) {
        console.log("playback error");
      }
    }

    dispatch({ type: PLAYBACK_STARTED, record });
  };
}

export function startPlayingTrack(track, record) {
  return function(dispatch, getState) {
    const state = getState();
    reportEvent(
      "track played",
      `${track.attributes.name} - ${record && record.attributes.artistName}`
    );
    if (flag(Flags.actuallyPlayMusic)) {
      dispatch(setupEventListening());
      mk()
        .authorize()
        .then(function() {
          mk()
            .setQueue({
              song: track.attributes.playParams.catalogId || track.id
            })
            .then(() => {
              try {
                mkplayer().play();
              } catch (err) {
                dispatch(flash(`Playback error! ${err}`, "error"));
                reportEvent(
                  "tracked play failed",
                  `${track.attributes.name} - ${record &&
                    record.attributes.artistName}`
                );
              }
            });
        });
    }
    //dispatch(clearSearchUIPhase());
    dispatch(setSelectedRecord(record));

    dispatch({ type: PLAYBACK_STARTED, track });
  };
}

export function recordPlayPressed(record, redirectToNowPlayingItem = true) {
  return function(dispatch, getState) {
    const { currentlyPlayingRecord } = getState().record;
    if (!currentlyPlayingRecord || currentlyPlayingRecord.id !== record.id) {
      if (isPlaylist(record)) {
        dispatch(startPlayingPlaylist(record, redirectToNowPlayingItem));
      } else {
        dispatch(startPlayingRecord(record, redirectToNowPlayingItem));
      }
    }
  };
}

export function trackPlayPressed(track, record) {
  return function(dispatch, getState) {
    dispatch(startPlayingTrack(track, record));
    // const { currentlyPlayingRecord } = getState().record;
    // if (!currentlyPlayingRecord || currentlyPlayingRecord.id !== record.id) {
    //   dispatch(startPlayingTrack(track));
    // }
  };
}

export function recordSelected(
  record,
  layoutRect,
  turnOverInitially = false,
  domId = null
) {
  return function(dispatch, getState) {
    //dispatch(scrollYTo(0));
    //dispatch(setPickedItem({ key: "nowPlaying", kind: "record" }));

    dispatch(setSelectedRecord(record, layoutRect, turnOverInitially, domId));
  };
}
