import React from "react";
import styles from "./playlist.module.scss";
import { TouchableOpacity } from "react-native-web";
import { isPlaylist } from "../../helpers";
import { recordSelected } from "../../actions/playback-actions";
import connect from "react-redux/es/connect/connect";
import _ from "lodash";
import {
  AnimationStates,
  SET_SELECTION_ANIMATION_STATE
} from "../../reducers/record-reducer";

function Playlist(props) {
  const { playlist } = props;
  if (_.trim(_.get(playlist, "attributes.name", "")).length === 0) return null;
  return (
    <TouchableOpacity onPress={() => props.recordSelected(playlist)}>
      <div className={styles.container}>
        {" "}
        {playlist.id}:{playlist.attributes.name}
      </div>
    </TouchableOpacity>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    recordSelected: (record, layoutRect) => {
      dispatch(recordSelected(record, layoutRect, isPlaylist(record)));
      dispatch({
        type: SET_SELECTION_ANIMATION_STATE,
        state: AnimationStates.OutComplete
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Playlist);
