import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Shelf from "../Shelf/shelf";
import Results from "../Results/results";

import ShelfPicker, {
  recentlyAddedShelfItem,
  recentlyPlayedShelfItem,
  staffPicksShelfItem
} from "../ShelfPicker/shelf-picker";

import { AutoPreviewSwitch } from "../ConnectedPropertySwitch/connected-property-switch";
import { SET_SHELF_LAYOUT } from "../../reducers/record-reducer";
import styles from "./picked-item.module.scss";
import { isContainedInIosNativeApp } from "../../helpers/native-ios-mk-implementation";
import _ from "lodash";

const itemMap = {
  AutoPreviewSwitch: (
    <div style={{ marginTop: 6 }}>{<AutoPreviewSwitch />}</div>
  ),
  SortingSwitch: <div style={{ marginTop: 6 }}>{/*<SortingSwitch />*/}</div>
};

function PickedItem(props) {
  const { pickedItem } = props;

  const nativeStyleAdjustments = isContainedInIosNativeApp()
    ? {
        paddingBottom: 15
      }
    : {};

  const nativeSearchStyleAdjustments = isContainedInIosNativeApp()
    ? {
        visibility: "none"
      }
    : {};

  if (!pickedItem) return;
  if (
    pickedItem.kind === "search"
    //   &&
    // (searchQuery || selectedArtist || (results&& results.length>0))
  ) {
    return (
      <div>
        <div
          style={{
            float: "right",
            ...nativeSearchStyleAdjustments
          }}
        >
          <ShelfPicker />
        </div>
        <Results />
      </div>
    );
  } else {
    return (
      <Shelf
        index={pickedItem.id}
        recordClass={"library-bold"}
        allowRefresh={false}
        showLoading={true}
        {...pickedItem.props}
        limit={props.limit}
        layoutCode={props.layoutCode || pickedItem.layoutCode}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            marginBottom: 4
          }}
        >
          {(pickedItem.leftItems || []).map(name => itemMap[name])}
        </div>
        {props.showPicker && (
          <div
            style={{
              float: "right",
              marginBottom: 4,
              ...nativeStyleAdjustments
            }}
          >
            <ShelfPicker />
          </div>
        )}
      </Shelf>
    );
  }
}

const mapStateToProps = state => {
  const pickedItem = _.get(state.ui, "pickedItem") || recentlyPlayedShelfItem;
  const layoutCode =
    state.record.shelves[pickedItem.id] &&
    state.record.shelves[pickedItem.id].layoutCode;
  return {
    searchQuery: state.search.text,
    selectedArtist: state.search.selectedArtist,
    results: state.search.results,
    pickedItem,
    scratchRecords: state.record.shelves[8].records,
    palette: state.ui.palette,
    layoutCode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setShelfLayout: (shelfId, layout) => {
      dispatch({ type: SET_SHELF_LAYOUT, shelfId, layout });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PickedItem);
