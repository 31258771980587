import React, { useEffect, useState } from "react";
import { DataProvider, withData } from "react-orbitjs";
import { View } from "react-native";
import { developerToken, fetchWithAuth } from "../../helpers";
import JSONAPISource from "@orbit/jsonapi";
import { Schema } from "@orbit/data";
import MemorySource from "@orbit/memory";
import IndexedDBSource from "@orbit/indexeddb";
import { ScrollView } from "react-native-web";
import styles from "../Home/home.module.scss";
import {
  authenticate,
  unauthenticate
} from "../../actions/authentication-actions";
import { loadUserData } from "../../actions/record-actions";
import { setPickedItem } from "../../actions/ui-actions";
import { staffPicksShelfItem } from "../ShelfPicker/shelf-picker";
import { connect } from "react-redux";
import { ShelfComponent } from "../Shelf/shelf";
import { withCookies } from "react-cookie";
import ButtonRow from "../Controls/ButtonRow/button-row";
import moment from "moment";
import { EndPoints } from "../../helpers/flags";

function OrbitPlaygroundComponent(props) {
  const {
    libraryAlbums,
    recentlyPlayed,
    recentlyAdded,
    libraryPlaylists
  } = props;

  return (
    <div
      style={{
        overflow: "auto",
        maxWidth: "68rem",
        padding: "2rem",
        margin: "auto",
        height: 800,
        backgroundColor: "lightblue"
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex"
        }}
      >
        <ScrollView containerStyle={{ display: "flex" }} overScrollMode="never">
          <h2>orbit playground</h2>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              <ul>
                {libraryPlaylists &&
                  libraryPlaylists.map(playlist => {
                    return (
                      <li key={playlist.id}>{playlist.attributes.name}</li>
                    );
                  })}
              </ul>
            </div>
            <div>
              <h2>recently played</h2>
              <ul>
                {recentlyPlayed &&
                  recentlyPlayed.map(album => {
                    return (
                      <ButtonRow
                        superTitle={album.attributes.fetchSequenceIndex}
                        title={album.attributes.name}
                      />
                    );
                  })}
              </ul>
            </div>
            <div>
              <h2>recently added</h2>
              <ul>
                {recentlyAdded &&
                  recentlyAdded.map(album => {
                    return (
                      <ButtonRow
                        superTitle={album.attributes.fetchSequenceIndex}
                        title={album.attributes.name}
                      />
                    );
                  })}
              </ul>
            </div>
          </div>

          <button
            id="apple-music-unauthorize"
            onClick={() => {
              props.unauthenticate(props.cookies);
              return true;
            }}
          />
          <button
            id="apple-music-authorize"
            onClick={() => {
              props.authenticate(props.cookies);
              return true;
            }}
          />
        </ScrollView>
      </div>
    </div>
  );
}

const mapRecordsToProps = ownProps => {
  return {
    //libraryPlaylists: q => q.findRecords("libraryPlaylist"),
    recentlyPlayed: q =>
      q
        .findRecords("album")
        .filter({ attribute: "source", value: EndPoints.recentlyPlayed })
        .sort("fetchSequenceIndex"),
    recentlyAdded: q =>
      q
        .findRecords("album")
        .filter({ attribute: "source", value: EndPoints.recentlyAdded })
        .sort("fetchSequenceIndex")
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    authenticate: cookies => {
      dispatch(authenticate(cookies, null, loadUserData));
    },
    unauthenticate: cookies => {
      dispatch(unauthenticate(cookies));
    }
  };
};

const OrbitPlayground = withData(mapRecordsToProps)(
  withCookies(
    connect(
      () => {},
      mapDispatchToProps
    )(OrbitPlaygroundComponent)
  )
);

export default OrbitPlayground;
