import React from "react";
import {
  SEARCH_UI_PHASE_ARTIST_SELECTED,
  SEARCH_UI_PHASE_NEW_RELEASES,
  SEARCH_UI_PHASE_RECOMMENDED,
  SEARCH_UI_PHASE_RESULTS
} from "../../reducers/search-reducer";
import {
  clearSearchUIPhase,
  setSearchText,
  setSearchUIPhase
} from "../../actions/search-actions";
import { connect } from "react-redux";
import styles from "./pane.module.scss";
import Catalogs from "../Catalogs/catalogs";
import Results from "../Results/results";
import { jlog } from "../../helpers";
import { stopPreviewingRecord } from "../../actions/playback-actions";
import {
  LOADING,
  LOADED,
  EXTERNAL_SOURCE_LABEL_RECORDS
} from "../../reducers/record-reducer";
class Pane extends React.Component {
  render() {
    const { palette, newReleasesLoaded, results } = this.props;
    const containerStyle = {
      backgroundColor: palette && palette.LightMuted.hex
    };
    const { uiPhase, dismiss } = this.props;
    let showRecommended,
      showResults = false;
    if (
      !(
        uiPhase === SEARCH_UI_PHASE_RECOMMENDED ||
        uiPhase === SEARCH_UI_PHASE_RESULTS ||
        uiPhase === SEARCH_UI_PHASE_ARTIST_SELECTED ||
        uiPhase === SEARCH_UI_PHASE_NEW_RELEASES
      )
    )
      return null;
    switch (uiPhase) {
      case SEARCH_UI_PHASE_RECOMMENDED:
        showRecommended = true;
        break;
      case SEARCH_UI_PHASE_NEW_RELEASES:
        showRecommended = true;
        break;
      case SEARCH_UI_PHASE_RESULTS:
        showResults = true;
        break;
      case SEARCH_UI_PHASE_ARTIST_SELECTED:
        showResults = true;
        break;
      default:
        break;
    }
    if (!showResults) return null;
    return (
      <div id={"pane"} className={styles.container} style={containerStyle}>
        <div className={styles.cancel}>
          <a onClick={dismiss} href={"#"}>
            <i className="fa fa-times" style={{ color: "#999" }} />
          </a>
        </div>
        {/* {showRecommended && newReleasesLoaded && <Catalogs />} */}
        {showResults && <Results />}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { labelRecordsShelfIndex } = state.record;

  return {
    results: state.search.results,

    uiPhase: state.search.uiPhase,
    palette: state.playback.palette,
    newReleasesLoaded:
      state.record.externalSourceLoadState[EXTERNAL_SOURCE_LABEL_RECORDS] ===
        LOADED ||
      state.record.shelves[labelRecordsShelfIndex].records.length > 0
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dismiss: () => {
      dispatch(stopPreviewingRecord());
      dispatch(clearSearchUIPhase());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Pane);
