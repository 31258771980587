/*
     <!-- Global site tag (gtag.js) - Google Analytics -->
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-104147614-3"></script>
      <script>
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'UA-104147614-3');
      </script>

 */
import ReactGA from "react-ga";
import { Flags } from "./helpers/flags";
import { flag } from "./helpers/flags";
import { store } from "./index";
import _ from "lodash";
import { isMe } from "./actions/ui-actions";

export function initializeAnalytics() {
  ReactGA.initialize("UA-187973194-1");
}

export function reportEvent(
  category = "general",
  action = "general",
  label = "general"
) {
  if (flag(Flags.reportAnalyticsEvents) && !isMe()) {
    ReactGA.event({
      category,
      action,
      label
    });
  }
}
