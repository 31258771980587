import React from "react";
import connect from "react-redux/es/connect/connect";
import { recordSelected } from "../../actions/playback-actions";
import { addRecord, loadRecordIfNeeded } from "../../actions/record-actions";
import { fetchWithAuth, jlog, sizedUrl } from "../../helpers";
import styles from "./thick-spine-record.module.scss";
import moment from "moment";

class ThickSpineRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hover: false, record: props.record };
  }

  componentDidMount() {
    this.props.loadRecordIfNeeded(this.props.record);
  }

  render() {
    const { loaded } = this.props;
    const { recentsShelfIndex, isPlaying } = this.props;
    const {
      height,
      width,
      record,
      record: { id, type },
      emphasizeRecordName = false
    } = this.props;
    const a = record.attributes;

    const { hover } = this.state;
    const addButtonClass = hover
      ? styles["add-button"]
      : styles["add-button-hidden"];
    // set image and text width
    const imgWidth = height;
    const gutter = 0;
    const textWidth = width - gutter - imgWidth;
    const recordStyle = { gridTemplateColumns: `${imgWidth}px ${textWidth}px` };
    const iconWidth = 40;
    //  grid-template-columns: 60px 40px;
    const textContainerStyle = {
      gridTemplateColumns: `${textWidth - iconWidth}px ${iconWidth}px`
    };
    return (
      <div
        onMouseOver={() => this.setState({ hover: true })}
        onMouseOut={() => this.setState({ hover: false })}
        className={styles["container"]}
        style={recordStyle}
      >
        {loaded ? (
          <img
            style={{ width: `${imgWidth}px` }}
            alt={""}
            onClick={() => this.props.recordSelected(id)}
            src={sizedUrl(a.artwork.url, 250)}
          />
        ) : (
          <div className={styles.placeHolder} />
        )}

        <div className={styles["text-container"]} style={textContainerStyle}>
          <div>
            {emphasizeRecordName ? (
              <div>
                <div className={styles["artist-name"]}>{a.name}</div>
                <div className={styles["name"]}>{a.artistName}</div>
              </div>
            ) : (
              <div>
                <div className={styles["artist-name"]}>
                  {a.spinitronRank && `${a.spinitronRank}. `}
                  {a.artistName}
                </div>
                <div className={styles["name"]}>{a.name}</div>
              </div>
            )}
            {type === "albums" && (
              <div>
                <div className={styles["label"]}>{a.recordLabel}</div>
                <div className={styles["release-date"]}>
                  {moment(a.releaseDate).fromNow()}
                </div>
              </div>
            )}
          </div>
          <div className={addButtonClass}>
            {loaded && (
              <a
                onClick={() => {
                  this.props.addRecord(recentsShelfIndex, record);
                }}
              >
                <i
                  className="fa fa-plus-circle"
                  style={{ color: "lightblue" }}
                />
              </a>
            )}
          </div>
          <div></div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  let { record } = ownProps;
  if (!record.attributes && state.idToRecordMap[record.id])
    record = state.idToRecordMap[record.id];
  const { recentsShelfIndex } = state.record;
  return {
    loaded: !!record.attributes,
    record,
    recentsShelfIndex
  };
};

const mapDispatchToProps = (dispatch, getState) => {
  return {
    loadRecordIfNeeded: record => {
      dispatch(loadRecordIfNeeded(record));
    },
    addRecord: (shelfIndex, record) => {
      dispatch(addRecord(shelfIndex, record));
    },
    recordSelected: id => {
      dispatch(recordSelected(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThickSpineRecord);

/*
  "record": {
    "id": "l.gwpyojs",
    "type": "library-albums",
    "href": "/v1/me/library/albums/l.gwpyojs",
    "attributes": {
      "name": "Europa - EP",
      "playParams": {
        "id": "l.gwpyojs",
        "kind": "album",
        "isLibrary": true
      },
      "artistName": "Diplo",
      "artwork": {
        "width": 1200,
        "height": 1200,
        "url": "https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/35/a7/37/35a737c9-b050-8cd3-b18d-14f75437899e/873.jpg/{w}x{h}bb.jpeg"
      },
      "trackCount": 6
    }
  },
 */

/*
{
  "record": {
    "id": "709397994",
    "type": "albums",
    "href": "/v1/catalog/us/albums/709397994",
    "attributes": {
      "artwork": {
        "width": 1417,
        "height": 1417,
        "url": "https://is3-ssl.mzstatic.com/image/thumb/Music6/v4/26/ff/56/26ff56cb-460c-367a-a3cc-ca01b1b59af4/5099969756153_1417x1417_300dpi.jpg/{w}x{h}bb.jpeg",
        "bgColor": "ffffff",
        "textColor1": "030303",
        "textColor2": "2f2f2f",
        "textColor3": "353535",
        "textColor4": "595959"
      },
      "artistName": "Röyksopp",
      "isSingle": false,
      "url": "https://music.apple.com/us/album/junior/709397994",
      "isComplete": false,
      "genreNames": [
        "Dance",
        "Music",
        "Electronic",
        "Rock",
        "Jazz",
        "Bop",
        "Downtempo"
      ],
      "trackCount": 12,
      "isMasteredForItunes": false,
      "releaseDate": "2009-03-20",
      "name": "Junior",
      "recordLabel": "EMI France",
      "copyright": "℗  2009 Parlophone Music France"
    }
  },
  "time": "1560012881.302"
}
 */
