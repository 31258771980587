import React from "react";
import {
  LayoutAnimation,
  Text,
  StyleSheet,
  View,
  Image,
  TouchableOpacity
} from "react-native-web";


const BUTTON_ROW_HEIGHT = 45;
const DLBlue = "#55AAF9";
const errorBackgroundPink = "#ffeeee";
const osramAlertRed = "#d00";
const osramGrays = [
  "#58585a",
  "#a7a8aa",
  "#ddd1d3",
  "#e3e4e5",
  "#f5f6f8"
];
const images = {

};

export default class ButtonRow extends React.Component {
  errorText(errorText) {
    return (
        <View style={styles.errorContainer}>
          <Text style={styles.errorText}>{errorText}</Text>
        </View>
    );
  }

  constructor(props) {
    super(props);
    this.state = { detailViewVisible: false };
  }

  detailItemsView = items => {
    if (!items) return null;
    const _items = items.map(item => {
      return (
          <ButtonRow
              isChild={true}
              title={item[0]}
              rightText={item[1]}
              superTitle={item[2]}
              onPress={item[3]}
          />
      );
    });
    return <View style={styles.itemsContainer}>{_items}</View>;
  };

  toggleDetailView = () => {
    LayoutAnimation.linear();
    this.setState({ detailViewVisible: !this.state.detailViewVisible });
  };

  render() {
    var {
      hasError,
      rightIcon,
      errorText,
      detailView,
      detailItems,
      isChild,
      onPress,
      rightTextStyle,
      detailViewFixedOpen
    } = this.props;
    const { detailViewVisible } = this.state;
    if (!detailView)
      detailView =
          detailItems && detailItems.length > 0
              ? this.detailItemsView(detailItems)
              : null;
    let rightIconStyle = null;
    if (detailView !== null && !detailViewFixedOpen) {
      rightIcon = detailViewVisible ? "chevronUp" : "chevronDown";
      rightIconStyle = {
        alignSelf: "center",
        paddingRight: 4,
        color: osramGrays[2],
        fontSize: 15
      };
    } else rightIconStyle = this.props.rightIconStyle;
    const leftImage = this.props.leftImage ? (
        <Image
            style={{
              width: this.props.imageSize || 25,
              height: this.props.imageSize || 25,
              alignSelf: "center",
              resizeMode: "contain",
              tintColor: this.props.tintColor,
              ...this.props.imageStyle
            }}
            source={images[this.props.leftImage]}
        />
    ) : null;
    const leftIcon = null;
    // const leftIcon = this.props.leftIcon ? (
    //     <FontAwesome
    //         style={[
    //           styles.leftIcon,
    //           this.props.tintColor && { color: this.props.tintColor },
    //           this.props.leftIconStyle
    //         ]}
    //     >
    //       {Icons[this.props.leftIcon]}
    //     </FontAwesome>
    // ) : null;
    const hasIcon = this.props.leftView || leftIcon || leftImage;
    const rowStyle = this.props.titleComponent
        ? styles.componentTitleRow
        : styles.row;
    const rightColor = this.props.rightColor || osramGrays[1];
    const noRightIcon = this.props.disabled ? true : this.props.noRightIcon;
    const errorStyle =
        errorText || hasError
            ? { backgroundColor: errorBackgroundPink, paddingLeft: 4 }
            : null;
    const toggleStyle = detailViewVisible ? { color: "red" } : {};
    const detailViewToggleStyle = detailViewVisible
        ? { opacity: 0 }
        : { opacity: 1 };
    const childStyle = isChild
        ? { backgroundColor: "#fff", paddingLeft: 14 }
        : {};

    return (
        <View style={{ ...childStyle, opacity: this.props.disabled ? 0.5 : 1 }}>
          <TouchableOpacity
              testLabel={this.props.testLabel}
              disabled={this.props.disabled || (!detailView && !this.props.onPress)}
              style={[styles.touchable, this.props.touchableStyle, errorStyle]}
              onPress={evt => {
                if (this.props.onPress) return this.props.onPress(evt);
                if (detailView) this.toggleDetailView();
                this.props.onPress && this.props.onPress(evt);
              }}
          >
            <View style={childStyle}>
              {this.props.topBorder && <View style={styles.separator} />}

              <View style={[rowStyle, this.props.containerStyle]}>
                <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      paddingLeft:
                          this.props.leftView || leftIcon || leftImage ? 0 : 2
                    }}
                >
                  {hasIcon ? (
                      <View
                          style={[
                            styles.rowIconContainer,
                            this.props.rowIconContainerStyle
                          ]}
                      >
                        {this.props.iconBackgroundView}
                        {!this.props.hideLeftIcon &&
                        (this.props.leftView || leftIcon || leftImage)}
                      </View>
                  ) : null}
                  {this.props.titleComponent ? (
                      this.props.titleComponent
                  ) : (
                      <View
                          style={{
                            flex: 1,
                            justifyContent: "center",
                            alignItems: "flex-start"
                          }}
                      >
                        <View style={this.props.titleContainerStyle}>
                          {this.props.superTitle ? (
                              <View style={styles.superTitleContainer}>
                                <Text
                                    style={[
                                      styles.superTitleText,
                                      this.props.superTitleStyle
                                    ]}
                                >
                                  {this.props.superTitle}
                                </Text>
                              </View>
                          ) : null}
                          <Text
                              style={[styles.titleText, this.props.titleStyle || {}]}
                          >
                            {this.props.title}
                          </Text>
                        </View>
                        {this.props.titleLine2 ? (
                            <Text
                                style={[
                                  styles.titleLine2Text,
                                  this.props.textStyle || {}
                                ]}
                            >
                              {this.props.titleLine2}
                            </Text>
                        ) : null}
                      </View>
                  )}
                  <View style={styles.rightSection}>
                    {this.props.rightComponent ? (
                        <View
                            style={[
                              styles.rightComponentContainer,
                              detailViewToggleStyle
                            ]}
                        >
                          {this.props.rightComponent}
                        </View>
                    ) : null}
                    {this.props.rightText ? (
                        <Text
                            style={[
                              styles.rightText,
                              rightTextStyle,
                              { color: rightColor },
                              toggleStyle
                            ]}
                        >
                          {this.props.rightText}
                        </Text>
                    ) : null}
                    {/*{noRightIcon !== true && (onPress || detailView) && (*/}
                    {/*    <FontAwesome style={rightIconStyle || styles.rightIcon}>*/}
                    {/*      {Icons[rightIcon]}*/}
                    {/*    </FontAwesome>*/}
                    {/*)}*/}
                  </View>
                </View>
              </View>
              {errorText ? this.errorText(errorText) : null}
            </View>
          </TouchableOpacity>
          {!this.props.noBottomBorder && <View style={styles.separator} />}
          {detailViewVisible || detailViewFixedOpen ? detailView : null}
        </View>
    );
  }
}

var styles = StyleSheet.create({
  errorContainer: {
    marginTop: -6,
    paddingBottom: 4
  },
  errorText: {
    color: osramAlertRed,
    fontSize: 14
  },
  superTitleContainer: {
    marginTop: -2
  },
  superTitleText: {
    fontSize: 12,
    color: osramGrays[1]
  },
  touchable: {},
  componentTitleRow: {
    flexDirection: "row",
    paddingLeft: 0,
    alignItems: "center",
    marginLeft: 0,
    marginTop: 10,
    marginBottom: 10
  },
  row: {
    flexDirection: "row",
    height: BUTTON_ROW_HEIGHT,
    paddingLeft: 0,
    alignItems: "center",
    marginLeft: 0
  },
  rowIconContainer: {
    paddingTop: 6,
    width: 35,
    height: 35
  },
  leftIcon: {
    color: DLBlue,
    fontSize: 28,
    alignSelf: "center",
    paddingRight: 6
  },
  rightComponentContainer: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  rightSection: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignSelf: "center"
  },
  rightText: {
    alignSelf: "center",
    fontSize: 16,
    paddingRight: 6,
    paddingTop: 2,
    color: osramGrays[1]
  },
  rightIcon: {
    alignSelf: "center",
    paddingTop: 5,
    paddingRight: 4,
    color: osramGrays[2],
    fontSize: 20
  },
  titleText: {
    color: "black",
    alignSelf: "flex-start",
    fontSize: 16
  },
  titleLine2Text: {
    color: osramGrays[1],
    alignSelf: "flex-start",
    fontSize: 10
  },
  separator: {
    height: 1,
    backgroundColor: osramGrays[2],
    opacity: 1
  }
});
