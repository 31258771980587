import { flag, Flags } from "../helpers/flags";
import {
  recentlyPlayedShelfItem,
  recentsShelfItem,
  staffPicksShelfItem
} from "../components/ShelfPicker/shelf-picker";
import { isContainedInIosNativeApp } from "../helpers/native-ios-mk-implementation";

export const SET_PALETTE = "SET_PALETTE";
export const SET_NORMAL_PALETTE = "SET_NORMAL_PALETTE";
export const UPDATE_DOCUMENT_ELEMENT = "UPDATE_DOCUMENT_ELEMENT";
export const SET_SCROLLING = "SET_SCROLLING";
export const SET_PREVIEW_READY = "SET_PREVIEW_READY";
export const SET_RECORD_STORY_ZOOM_LEVEL = "SET_RECORD_STORY_ZOOM_LEVEL";
export const SET_SCROLL_XY = "SET_SCROLL_XY";
export const SCROLL_Y_TO = "SCROLL_Y_TO";
export const SET_PICKED_ITEM = "SET_PICKED_ITEM";
export const SET_BOOKMARK = "SET_BOOKMARK";
export const CLEAR_BOOKMARK = "CLEAR_BOOKMARK";
export const FLASH = "FLASH";
export const SET_SWITCH = "SET_SWITCH";
export const TOGGLE_SWITCH = "TOGGLE_SWITCH";
export const SET_HOVER_ELEMENT_ID = "SET_HOVER_ELEMENT_ID";
export const CLEAR_ME_ALBUMS_COUNT = "CLEAR_ME_ALBUMS_COUNT";
export const INCREMENT_ME_ALBUMS_COUNT = "INCREMENT_ME_ALBUMS_COUNT";
export const SET_CONTAINER_INFO = "SET_CONTAINER_INFO";

export const loggedInPalette = {
  bookmark: 0,
  shelfBlueColor: "#00afd8",
  backgroundColor: "#00afd8",
  textColor: "#333",
  lightTextColor: "#777",
  lighterBackgroundColor1: "#92d9e8",
  lighterBackgroundColor2: "#5ccbdd",
  lighterBackgroundColor3: "#acdcf0",
  heavyColor: "black",
  alertRedColor: "#dc2334",
  lighterAlertColor: "#e98e93"
};

export const loggedOutPalette = {
  ...loggedInPalette,
  backgroundColor: "#00afd8"
};

const initialState = {
  containerInfo: {},
  meAlbumsCount: 0,
  palette: loggedOutPalette,
  scroll: [0, 0],
  isScrolling: false,
  previewReady: true,
  documentElement: window.document.documentElement,
  scrollYTarget: null,
  pickedItem: recentlyPlayedShelfItem,
  currentGlobalRecordLayoutRect: null,
  flash: null,
  hoverElementId: null,
  switches: {
    autoPreview: 0
  }
};

const UiReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTAINER_INFO:
      return { ...state, containerInfo: action.info };
    case CLEAR_ME_ALBUMS_COUNT:
      return { ...state, meAlbumsCount: 0 };
    case INCREMENT_ME_ALBUMS_COUNT:
      return { ...state, meAlbumsCount: state.meAlbumsCount + 1 };
    case FLASH:
      return {
        ...state,
        flash: { message: action.message, kind: action.kind }
      };
    case SET_HOVER_ELEMENT_ID:
      return { ...state, hoverElementId: action.id };
    case SET_SWITCH:
      const switchesCopy = { ...state.switches };
      switchesCopy[action.key] = action.value;
      return { ...state, switches: switchesCopy };
    case TOGGLE_SWITCH:
      const switchesCopy_ = { ...state.switches };
      switchesCopy_[action.key] = !(switchesCopy_[action.key] || false);
      return { ...state, switches: switchesCopy_ };
    case "setCurrentGlobalRecordLayoutRect":
      const valid = action.rect && action.rect.height >= action.rect.width;
      return valid
        ? { ...state, currentGlobalRecordLayoutRect: action.rect }
        : state;
    case SET_PICKED_ITEM:
      return { ...state, pickedItem: action.itemData };
    case SET_BOOKMARK:
      return { ...state, bookmark: state.scroll };
    case CLEAR_BOOKMARK:
      return { ...state, bookmark: null };
    case SCROLL_Y_TO:
      return {
        ...state,
        scrollYTarget: action.y
      };
    case SET_SCROLL_XY:
      return {
        ...state,
        scroll: [action.x, action.y],
        scrollYTarget: null
      };
    case SET_PALETTE: {
      const { palette } = action;
      return { ...state, ...{ palette } };
    }
    case SET_RECORD_STORY_ZOOM_LEVEL: {
      return { ...state, recordStoryZoomLevel: action.level };
    }
    // case SET_NORMAL_PALETTE: {
    //   return { ...state, palette: normalPalette };
    // }
    case UPDATE_DOCUMENT_ELEMENT: {
      const {
        documentElement: { clientWidth, clientHeight }
      } = action;
      return { ...state, documentElement: { clientWidth, clientHeight } };
    }
    case SET_SCROLLING: {
      const isScrolling = action.b;
      return { ...state, ...{ isScrolling } };
    }
    case SET_PREVIEW_READY: {
      const previewReady = action.b;
      return { ...state, ...{ previewReady } };
    }
    default:
      return state;
  }
};

export default UiReducer;
