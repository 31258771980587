import HomeLoggedOutRedShelf from "../components/Home/home-logged-out-red-shelf";
import HomeLoggedIn from "../components/Home/home";

export const ProdDataLoadingProfile = {
  reduxPersistVersion: 439,
  useReduxPersist: true,
  shelfLimit: null,
  recentlyAdded: 150,
  recentlyPlayedAM: 150,
  recentlyPlayedHere: 0,
  libraryAlbums: 2000,
  labelRecordsToSend: 50
};

export const DevDataLoadingProfile = {
  ...ProdDataLoadingProfile,
  useReduxPersist: false,
  recentlyAdded: 50,
  recentlyPlayedAM: 100,
  recentlyPlayedHere: 0,
  libraryAlbums: 150,
  labelRecordsToSend: 10,
  shelfLimit: null
};

export const FlagValues = {
  useFastRecordStyling: { dev: true, prod: false },
  useRandomRecordData: { dev: false, prod: false },
  debugRecord: { dev: false, prod: false },
  actuallyPlayMusic: { dev: true, prod: true },
  useRecordStory: { dev: true, prod: true },
  usePlayground: { dev: false, prod: false },
  updateProgress: { dev: false, prod: false },
  showDebugInfo: { dev: true, prod: true },
  previewingActive: { dev: true, prod: true },
  loadUserData: { dev: true, prod: true },
  showDebugLog: { dev: true, prod: false },
  showBigSwipeCover: { dev: true, prod: true },
  useRecordStoryDesignMode: { dev: true, prod: true },
  showRecordDebugInfo: { dev: false, prod: false },
  reportAnalyticsEvents: { dev: false, prod: true }
};

export const ConstantValues = {
  dataLoadingProfile: {
    dev: DevDataLoadingProfile,
    prod: ProdDataLoadingProfile
  },
  numLabelRecords: 1,
  offsetInterfaceBy: { dev: 0, prod: 0 },
  optionSet: { dev: "dev", prod: "prod" },
  waitTimeToSavePlayback: { dev: 10 * 1000 * 5, prod: 60 * 1000 * 5 },
  playgroundClass: { dev: null, prod: null },
  debugInfoToShow: { dev: ["playbackState"], prod: ["playbackState"] },
  baseUrl: {
    dev: "https://shelffm.ngrok.io",
    prod: "https://shelffm.ngrok.io"
  },
  discographySource: { dev: "apple music", prod: "apple music" },
  defaultRecordStoryZoomLevel: { dev: 40, prod: 50 },
  loggedOutHomepageComponent: {
    dev: HomeLoggedIn,
    prod: HomeLoggedOutRedShelf
  }
};

export const Constants = {
  dataLoadingProfile: "dataLoadingProfile",
  offsetInterfaceBy: "offsetInterfaceBy",
  numLabelRecords: "numLabelRecords",
  optionSet: "optionSet",
  waitTimeToSavePlayback: "waitTimeToSavePlayback",
  playgroundClass: "playgroundClass",
  debugInfoToShow: "debugInfoToShow",
  baseUrl: "baseUrl",
  discographySource: "discographySource",
  defaultRecordStoryZoomLevel: "defaultRecordStoryZoomLevel",
  loggedOutHomepageComponent: "loggedOutHomepageComponent"
};

export const EndPoints = {
  libraryAlbums: "me/library/albums",
  recentlyAdded: "me/library/recently-added",
  recentlyPlayed: "me/recent/played"
};

export function devMode() {
  return !window.location.href.includes("shelf.fm");
}

export function constant(constant) {
  const constantValue = ConstantValues[constant];
  if (!constantValue) return null;
  return devMode()
    ? constantValue[ConstantValues.optionSet.dev]
    : constantValue[ConstantValues.optionSet.prod];
}

export function flag(flag) {
  const flagValue = FlagValues[flag];
  if (!flagValue) return null;
  return devMode() ? flagValue.dev : flagValue.prod;
}

export const Flags = {
  useFastRecordStyling: "useFastRecordStyling",
  useRandomRecordData: "useRandomRecordData",
  debugRecord: "debugRecord",
  actuallyPlayMusic: "actuallyPlayMusic",
  useRecordStory: "useRecordStory",
  usePlayground: "usePlayground",
  updateProgress: "updateProgress",
  showDebugInfo: "showDebugInfo",
  previewingActive: "previewingActive",
  loadUserData: "loadUserData",
  showDebugLog: "showDebugLog",
  showBigSwipeCover: "showBigSwipeCover",
  useRecordStoryDesignMode: "useRecordStoryDesignMode",
  showRecordDebugInfo: "showRecordDebugInfo",
  reportAnalyticsEvents: "reportAnalyticsEvents"
};
