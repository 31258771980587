import { TOGGLE_OVERPLAYER } from "./playback-reducer";
import { Flags } from "../helpers/flags";
import { flag, FlagValues } from "../helpers/flags";

export const LOG = "LOG";
export const TOGGLE_DEBUG_LOG = "TOGGLE_DEBUG_LOG";
export const TURN_OFF_DEBUG_LOG = "TURN_OFF_DEBUG_LOG";
export const CLEAR_DEBUG_LOG = "CLEAR_DEBUG_LOG";
const showDebugLog = flag(Flags.showDebugLog);
const initialState = { showDebugLog, log: [] };
const DebugReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_DEBUG_LOG: {
      return { ...state, showDebugLog: !state.showDebugLog };
    }
    case TURN_OFF_DEBUG_LOG: {
      return { ...state, showDebugLog: false };
    }
    case CLEAR_DEBUG_LOG: {
      return { ...state, log: [] };
    }
    case LOG: {
      return { ...state, log: [action.message, ...state.log] };
    }

    default:
      return state;
  }
};
export default DebugReducer;
