import Reactotron, { trackGlobalErrors } from "reactotron-react-js";
import { reactotronRedux } from "reactotron-redux";
import { jlog } from "./helpers";
import { isContainedInIosNativeApp } from "./helpers/native-ios-mk-implementation";
let reactotron;
jlog({ env: process.env.NODE_ENV });
jlog({
  isContainedInIosNativeApp: isContainedInIosNativeApp() ? "true" : "false"
});
if (process.env.NODE_ENV !== "production" && !isContainedInIosNativeApp()) {
  reactotron = Reactotron.configure({
    host: "localhost",
    name: "Shelf"
  })
    // .use(openInEditor())
    // .use(overlay())
    // .use(asyncStorage())
    // .use(networking())
    .use(reactotronRedux()) //  <- here i am!
    // .use(storybook()) // <--- here we go!
    .use(
      trackGlobalErrors({
        veto: frame => {
          return frame.fileName.indexOf("node_modules") <= 0;
        }
      })
    )
    .connect();
  console.log("redirecting log to reactotron ...");
  console.log = Reactotron.log;
}

export default reactotron;
