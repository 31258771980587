import React from "react";
import _ from "lodash";
import styles from "./record.module.scss";
export default function RecordPlaceholder(props) {
  const { width, height, record } = props;
  return (
    <div
      className={styles.placeholder}
      style={{
        width,
        height
      }}
    >
      <div>{record.attributes.artistName}</div>
      <div>{record.attributes.name}</div>
    </div>
  );
}
