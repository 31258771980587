import "./ReactotronConfig";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import persistedReducer, { USE_REDUX_PERSIST } from "./reducers";
import thunk from "redux-thunk";
import { CookiesProvider } from "react-cookie";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import "font-awesome/css/font-awesome.min.css";
import Playground from "./components/Playground/playground";
import { Flags } from "./helpers/flags";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Router, Switch } from "react-router-dom";
import { hydrate, render } from "react-dom";
import { setBackgroundColor } from "./components/Playground/native-player-playground";
import { constant, Constants, flag } from "./helpers/flags";
import createCompressor from "redux-persist-transform-compress";
import Reactotron from "./ReactotronConfig";
import { isContainedInIosNativeApp } from "./helpers/native-ios-mk-implementation";
import { jlog } from "./helpers";
import { MEASURE } from "./reducers/service-status-reducer";

window.receiveDataFromNative = d => {
  console.log("received data from native");
  //window.alert("alert")
  const color = d === 1 ? "lightblue" : "lightgreen";
  setBackgroundColor(color);
  // receivedPlaybackInfo = d
  //return `data received: ${d} (global heartbeat: ${globalHeartbeat}`
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let _store;
jlog({ env: process.env.NODE_ENV });

if (process.env.NODE_ENV !== "production" && !isContainedInIosNativeApp()) {
  _store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk), Reactotron.createEnhancer())
  );
} else {
  _store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk))
  );
}

export const store = _store;

const compressor = createCompressor();

let persistor = persistStore(store, { transforms: [compressor] });
const PlaygroundClass = constant(Constants.playgroundClass);
let app = null;
if (USE_REDUX_PERSIST) {
  app = (
    <CookiesProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {flag(Flags.usePlayground) ? <PlaygroundClass /> : <App />}
        </PersistGate>
      </Provider>
    </CookiesProvider>
  );
} else {
  app = (
    <CookiesProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </CookiesProvider>
  );
}

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(app, rootElement);
} else {
  render(app, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
//
// if (module.hot) {
//   module.hot.accept();
// }
