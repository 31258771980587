import connect from "react-redux/es/connect/connect";
import React, { useState, useEffect } from "react";
import { bestRecord, jlog } from "../../helpers";
import _ from "lodash";
import {
  playPause,
  recordPlayPressed,
  recordSelected,
  startPreviewingRecord,
  stopPreviewingRecord,
  trackPlayPressed,
  transitionPreviewToPlay
} from "../../actions/playback-actions";
import { TURN_RECORD_OVER } from "../../reducers/record-reducer";
import {
  addRecord,
  deleteRecord,
  loadRecordIfNeeded,
  loadTracksIfNeeded
} from "../../actions/record-actions";
import InlinePlaylistPicker from "../InlinePlaylistPicker/inline-playlist-picker";
import styles from "./track.module.scss";
import { TouchableOpacity } from "react-native-web";

function Track(props) {
  const {
    track,
    track: { id, attributes: attr },
    index,
    klass = "default",
    record,
    playing
  } = props;

  const [showDetails, setShowDetails] = useState(false);

  function play(event) {
    event.stopPropagation();
    event.preventDefault();
    props.trackPlayPressed(event, track, record);
    return true;
  }

  function onPick(playlistId) {
    setTimeout(() => setShowDetails(false), 500);
  }
  const indicatorStyle = {
    transition: "all 0.25s",
    transform: `rotate(${showDetails ? "-180deg" : "0deg"})`
  };
  const opacityDelay = showDetails ? "0.1s" : "0s";
  const heightDelay = showDetails ? "0s" : "0.25s";
  const detailsStyle = {
    transition: `opacity 0.5s ${opacityDelay}, transform 0.5s ${heightDelay},max-height 0.5s ${heightDelay}`,
    opacity: showDetails ? 1 : 0,
    maxHeight: showDetails ? 1000 : 0
    //transform: showDetails ? "scaleY(1)" : "scaleY(0)"
  };

  return (
    <div>
      <div className={styles[klass]}>
        <div className={styles["index"]}>
          {playing ? <i className="fa fa-volume-up" /> : index + 1}
        </div>
        <TouchableOpacity onPress={event => play(event)}>
          <div className={styles["title"]}>
            {attr.name}
            <div className={styles["duration"]}>
              &nbsp;
              {Math.round(attr.durationInMillis / 60000)}m
            </div>
            <div className={styles["artists"]}>{attr.artistName}</div>
          </div>
        </TouchableOpacity>
        <div className={styles.more}>
          <TouchableOpacity
            hitSlop={{ top: 5, left: 100, bottom: 5, right: 10 }}
            onPress={e => setShowDetails(showDetails => !showDetails)}
          >
            <div className={styles.icon}>
              <i style={indicatorStyle} className="fa fa-chevron-down" />
            </div>
          </TouchableOpacity>
        </div>
      </div>
      <div className={styles.details} style={detailsStyle}>
        {showDetails && <InlinePlaylistPicker track={track} onPick={onPick} />}
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const songName = _.get(state.playback, "mediaItem.attributes.name");
  const playing = songName === ownProps.track.attributes.name;
  return { playing };
};

const mapDispatchToProps = dispatch => {
  return {
    showPlaylistPicker: trackId => {
      dispatch({ type: "SHOW_PLAYLIST_PICKER", trackId });
    },
    transitionPreviewToPlay: (record, layoutRect) =>
      dispatch(transitionPreviewToPlay(record, layoutRect)),
    startPreviewingRecord: record => dispatch(startPreviewingRecord(record)),
    stopPreviewingRecord: record => dispatch(stopPreviewingRecord(record)),
    turnRecordOver: record => {
      dispatch({ type: TURN_RECORD_OVER, record });
    },
    loadRecordIfNeeded: record => {
      dispatch(loadRecordIfNeeded(record));
    },
    loadTracksIfNeeded: record => {
      dispatch(loadTracksIfNeeded(record));
    },
    deleteRecord: (shelfIndex, id) => {
      dispatch(deleteRecord(shelfIndex, id));
    },
    addRecord: (shelfIndex, id) => {
      dispatch(addRecord(shelfIndex, id));
    },
    recordSelected: (record, layoutRect) => {
      dispatch(recordSelected(record, layoutRect));
    },
    playPause: () => dispatch(playPause()),

    trackPlayPressed: (event, track, record) => {
      event.stopPropagation();
      dispatch(trackPlayPressed(track, record));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Track);
