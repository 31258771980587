import React, { useState, useEffect } from "react";
import _ from "lodash";

import connect from "react-redux/es/connect/connect";
import styles from "./flash.module.scss";
import { flash } from "../../actions/ui-actions";

var classNames = require("classnames");

function Flash(props) {
  const [animationClass, setAnimationClass] = useState("hidden");

  useEffect(() => {
    if (props.message) {
      setAnimationClass("shown");
      props.kind !== "error" &&
        setTimeout(() => {
          setAnimationClass("hidden");
          setTimeout(() => props.flash(null), 500);
          //props.flash(null);
        }, 3000);
    }
  }, [props.message]);

  return (
    <div
      onClick={() => {
        setAnimationClass("hidden");
        setTimeout(() => props.flash(null), 500);
      }}
      className={styles[props.kind]}
    >
      <div className={styles[animationClass]}>{props.message}</div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    message: _.get(state.ui, "flash.message"),
    kind: _.get(state.ui, "flash.kind")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    flash: message => dispatch(flash(message))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Flash);
