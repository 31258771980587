
const initialState = { calls: [] };
const ApiReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_TO_API_LOG": {
            const newCalls=[...state.calls]
            newCalls.push({url:action.url,json:action.json,response: action.response})
            return { ...state, calls: newCalls };
        }
        default:
            return state;
    }
};
export default ApiReducer;
