import React, { useState } from "react";
import connect from "react-redux/es/connect/connect";
import styles from "./inline-playlist-picker.module.scss";
import { TouchableOpacity } from "react-native-web";
import _ from "lodash";
import { addTrackToPlaylist } from "../../actions/record-actions";
import { withData } from "react-orbitjs";

function InlinePlaylistPickerComponent(props) {
  const [showAll, setShowAll] = useState(false);

  function playlistPicked(playlist, track) {
    props.onPick && props.onPick(playlist);
    props.playlistPicked(playlist, track);
  }

  const playlists = showAll ? props.playlists : _.slice(props.playlists, 0, 18);
  return (
    <div className={styles.container}>
      <div className={styles.header}>Add to playlist ...</div>
      <div className={styles.playlists}>
        {playlists.map(playlist => (
          <TouchableOpacity
            onPress={() => playlistPicked(playlist, props.track)}
          >
            <div className={styles.playlist}>{playlist.attributes.name}</div>
          </TouchableOpacity>
        ))}
        {!showAll && (
          <TouchableOpacity onPress={() => setShowAll(true)}>
            <div className={styles.showAll}>Show all ...</div>
          </TouchableOpacity>
        )}
      </div>
    </div>
  );
}

const mapRecordsToProps = (state, ownProps) => {
  return {
    playlists: q => q.findRecords("libraryPlaylist")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    playlistPicked: (playlist, track) => {
      dispatch(addTrackToPlaylist(playlist, track));
    }
  };
};

const InlinePlaylistPicker = withData(mapRecordsToProps)(
  connect(
    () => {},
    mapDispatchToProps
  )(InlinePlaylistPickerComponent)
);

export default InlinePlaylistPicker;
