import {
  AUTHENTICATE,
  UNAUTHENTICATE
} from "../actions/authentication-actions";

const initialState = { loggedIn: false };
const AuthenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE: {
      return { ...state, storefrontId: action.storefrontId, loggedIn: true };
    }
    case UNAUTHENTICATE: {
      return { ...state, loggedIn: false };
    }
    default:
      return state;
  }
};
export default AuthenticationReducer;
