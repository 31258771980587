import React from "react";
import connect from "react-redux/es/connect/connect";
import styles from "./artist.module.scss";
import _ from "lodash";
import { jlog, sizedUrl } from "../../helpers";

class Artist extends React.Component {
  constructor(props) {
    super(props);
    this.state = { artist: this.props.artist };
  }

  render() {
    const { artist } = this.state;
    const { onClick } = this.props;
    const url = _.get(
      artist,
      "relationships.albums.data[0].attributes.artwork.url"
    );
    return (
      <div onClick={onClick} className={styles.container}>
        <div className={styles.icon}>
          {url ? (
            <img src={sizedUrl(url, 60)} />
          ) : (
            <div className={styles.placeHolder} />
          )}
        </div>
        <div className={styles.name}>
          {artist.attributes.name}
          {/*({_.join(artist.attributes.genreNames,'/')})*/}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, getState) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Artist);

/*
  "record": {
    "id": "l.gwpyojs",
    "type": "library-albums",
    "href": "/v1/me/library/albums/l.gwpyojs",
    "attributes": {
      "name": "Europa - EP",
      "playParams": {
        "id": "l.gwpyojs",
        "kind": "album",
        "isLibrary": true
      },
      "artistName": "Diplo",
      "artwork": {
        "width": 1200,
        "height": 1200,
        "url": "https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/35/a7/37/35a737c9-b050-8cd3-b18d-14f75437899e/873.jpg/{w}x{h}bb.jpeg"
      },
      "trackCount": 6
    }
  },
 */

/*
{
  "record": {
    "id": "709397994",
    "type": "albums",
    "href": "/v1/catalog/us/albums/709397994",
    "attributes": {
      "artwork": {
        "width": 1417,
        "height": 1417,
        "url": "https://is3-ssl.mzstatic.com/image/thumb/Music6/v4/26/ff/56/26ff56cb-460c-367a-a3cc-ca01b1b59af4/5099969756153_1417x1417_300dpi.jpg/{w}x{h}bb.jpeg",
        "bgColor": "ffffff",
        "textColor1": "030303",
        "textColor2": "2f2f2f",
        "textColor3": "353535",
        "textColor4": "595959"
      },
      "artistName": "Röyksopp",
      "isSingle": false,
      "url": "https://music.apple.com/us/album/junior/709397994",
      "isComplete": false,
      "genreNames": [
        "Dance",
        "Music",
        "Electronic",
        "Rock",
        "Jazz",
        "Bop",
        "Downtempo"
      ],
      "trackCount": 12,
      "isMasteredForItunes": false,
      "releaseDate": "2009-03-20",
      "name": "Junior",
      "recordLabel": "EMI France",
      "copyright": "℗  2009 Parlophone Music France"
    }
  },
  "time": "1560012881.302"
}
 */
