import {
  loadPlaylistRecordsIntoShelf,
  loadPlaylists
} from "../actions/record-actions";
import { EndPoints } from "./flags";

export const FunctionMap = {
  loadPlaylists: () => loadPlaylists(),
  libraryPlaylistsQuery: q => q.findRecords("libraryPlaylist"),
  loadStaffPicks: (dispatch, id) =>
    dispatch(loadPlaylistRecordsIntoShelf("pl.u-zPyLA2gFb9m7m", id)),
  loadPitchforkSelects: (dispatch, id) =>
    dispatch(
      loadPlaylistRecordsIntoShelf("pl.9107845577c24fe3be7193c55d7864b0", id)
    ),
  loadRecentlyPlayed: q =>
    q
      .findRecords("album")
      .filter({ attribute: "playedAt", op: "gt", value: 0 })
      .sort("index"),
  loadRecentlyAdded: q =>
    q
      .findRecords("album")
      .filter({ attribute: "source", value: EndPoints.recentlyAdded })
      .sort("-dateAdded")
};
