import React, { useState, useEffect } from "react";
import { Sparklines, SparklinesLine } from "react-sparklines";
import styles from "./measure.module.scss";
import connect from "react-redux/es/connect/connect";
import _ from "lodash";
function Measure(props) {
  const values = [...(props.totalMemoryUsageBytesArray || [])]
    .reverse()
    .map(v => (v / 1000000.0).toFixed(2));
  return (
    <div className={styles.container}>
      <Sparklines data={values} limit={60} width={500} height={20} margin={5}>
        <SparklinesLine color="white" />
      </Sparklines>
      <div className={styles.values}>
        {_.slice([...values], 0, 10000)
          .map(v => v)
          .map(v => (
            <div className={styles.value}>{v}</div>
          ))}
      </div>
      --
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    totalMemoryUsageBytesArray:
      state.serviceStatus.measure.totalMemoryUsageBytesArray
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Measure);
