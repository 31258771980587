import React, { useEffect, useState } from "react";
import styles from "./switch.module.scss";
export default function Switch(props) {
  const {
    backgroundColor = "grey",
    color = "white",
    height = 20,
    width = 30
  } = props;
  const [position, setPosition] = useState(props.position || 0);
  const handleWidth = width * 0.5;
  const handleTranslate = position === 0 ? 2 : width - handleWidth - 2;
  const handleColor = position === 0 ? color : color;
  const bgColor = position === 0 ? backgroundColor : backgroundColor;

  useEffect(() => {
    setPosition(props.position);
  }, [props.position]);

  function onClick() {
    let newPosition = null;
    setPosition(position => {
      if (position === 0) {
        newPosition = 1;
      } else {
        newPosition = 0;
      }
      props.onChange && props.onChange(newPosition);
      return newPosition;
    });
  }

  const containerStyle = {
    height,
    width,
    backgroundColor: bgColor,
    borderRadius: height / 2
  };

  const handleStyle = {
    opacity: 0.7,
    height: height - 4,
    width: handleWidth,
    backgroundColor: handleColor,
    transform: `translate(${handleTranslate}px,2px)`,
    borderRadius: height / 2
  };

  return (
    <div onClick={onClick} className={styles.container} style={containerStyle}>
      <div className={styles.handle} style={handleStyle} />
    </div>
  );
}
