import React, { useState, useEffect } from "react";
import styles from "./selection-animation-overlay.module.scss";
import { connect } from "react-redux";
import Record from "../Record/record";
import _ from "lodash";
import { jlog, sizedUrl, notchHeight } from "../../helpers";
import Cover from "../Record/cover";
import {
  AnimationStates,
  SET_SELECTION_ANIMATION_STATE
} from "../../reducers/record-reducer";
import { constant, Constants } from "../../helpers/flags";
import { BIG_RECORD_IMAGE_WIDTH_HEIGHT } from "../../helpers/constants";
export const SELECTION_ANIMATION_DURATION = 250;
function SelectionAnimationOverlay(props) {
  const heightAdjust = props.isContainedInCatalyst ? 140 : 90;

  const { clientWidth, clientHeight } = props;
  const bigRecordHeightWidth = Math.min(
    clientHeight - heightAdjust,
    clientWidth - 14
  );

  const [isCurrentlySelected, setIsCurrentlySelected] = useState(false);
  const [layoutRect, setLayoutRect] = useState(null);
  const [record, setRecord] = useState(null);
  const [transform, setTransform] = useState("scale(1) translate(0px,0px)");
  const [recordStyle, setRecordStyle] = useState({
    transition: `transform  ${SELECTION_ANIMATION_DURATION}ms`
  });
  const [coverStyle, setCoverStyle] = useState({
    transition: `transform ${SELECTION_ANIMATION_DURATION}ms ease-in-out`
  });
  const [containerStyle, setContainerStyle] = useState({
    top: constant(Constants.offsetInterfaceBy)
  });

  useEffect(() => {
    layoutRect &&
      setRecordStyle(recordStyle => ({
        ...recordStyle,
        position: "relative",
        left: layoutRect.x,
        top: layoutRect.y,
        width: layoutRect.width,
        height: layoutRect.width
      }));
  }, [layoutRect]);

  useEffect(() => {
    function style(recordStyle) {
      const s = {
        ...recordStyle,
        transform,
        display: "block"
      };
      return s;
    }
    //setRecordStyle(style);
    setTimeout(() => setRecordStyle(style), 5);
  }, [transform]);

  function isTransitioning() {
    return [
      AnimationStates.GoingOut,
      AnimationStates.GoingInPrep,
      AnimationStates.GoingIn,
      AnimationStates.Out // wait for imageload/outcomplete
    ].includes(props.selectionAnimationState);
  }

  function isGoingIn() {
    return [AnimationStates.GoingInPrep, AnimationStates.GoingIn].includes(
      props.selectionAnimationState
    );
  }

  useEffect(() => {
    if (isTransitioning()) {
      setContainerStyle(s => ({ ...s, height: clientHeight }));
    } else {
      setContainerStyle(s => ({ ...s, height: 0 }));
      // setTimeout(
      //   () => setContainerStyle(s => ({ ...s, height: 0 })),
      //   SELECTION_ANIMATION_DURATION
      // );
    }
  }, [props.selectionAnimationState]);

  useEffect(() => {
    let scale, translate, finalAnimationState, animationState;
    if (
      isCurrentlySelected &&
      layoutRect &&
      props.selectionAnimationState != AnimationStates.OutComplete
    ) {
      // jlog({ effect: "selected" });
      animationState = AnimationStates.GoingOut;
      finalAnimationState = AnimationStates.Out;

      const recordCenter = [
        layoutRect.x + layoutRect.width / 2,
        layoutRect.y + layoutRect.width / 2
      ];

      const bigRecordCenter = [
        clientWidth / 2,
        10 + notchHeight(clientHeight) + bigRecordHeightWidth / 2
      ];
      scale = bigRecordHeightWidth / layoutRect.width;
      translate = [
        bigRecordCenter[0] - recordCenter[0],
        bigRecordCenter[1] - recordCenter[1]
      ];
    } else {
      animationState =
        props.selectionAnimationState === AnimationStates.Out ||
        props.selectionAnimationState === AnimationStates.OutComplete
          ? AnimationStates.GoingInPrep
          : AnimationStates.In;
      finalAnimationState = AnimationStates.In;

      scale = 1;
      translate = [0, 0];
    }

    props.setSelectionAnimationState(animationState);
    document.getElementById(`cover`).ontransitionend = () => {
      props.setSelectionAnimationState(finalAnimationState);
    };
    const timeout = animationState === AnimationStates.GoingInPrep ? 10 : 0;
    setTimeout(
      () =>
        setTransform(
          `translate(${translate[0]}px,${
            translate[1]
          }px) scale(${scale}) rotate(0deg)`
        ),
      timeout
    );
  }, [isCurrentlySelected]);

  useEffect(() => {
    setIsCurrentlySelected(!!props.selected);
    if (props.selected) {
      setRecord(props.selected.record);
      setLayoutRect(props.selected.layoutRect);
    }
  }, [props.selected]);

  return (
    <div className={styles.container} style={containerStyle}>
      <div
        id={`cover`}
        className={styles.record}
        style={{ transition: "all 2s", ...recordStyle }}
      >
        {record && isTransitioning() && layoutRect && (
          <Cover
            // onImageLoad={() =>
            //   props.selectionAnimationState === AnimationStates.GoingInPrep &&
            //   setTimeout(() =>
            //     props.setSelectionAnimationState(AnimationStates.GoingIn, 50)
            //   )
            // }
            record={record}
            style={coverStyle}
            widthHeight={layoutRect.width}
            imgWidthHeight={isGoingIn() ? BIG_RECORD_IMAGE_WIDTH_HEIGHT : 250}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    isContainedInCatalyst: _.get(state.ui, "containerInfo.type") === "catalyst",
    selected: _.get(state.lightweightRecord, "selected"),
    clientHeight: state.ui.documentElement.clientHeight,
    clientWidth: state.ui.documentElement.clientWidth,
    selectionAnimationState: state.lightweightRecord.selectionAnimationState
  };
};

const mapDispatchToProps = (dispatch, getState) => {
  return {
    setSelectionAnimationState: state =>
      dispatch({ type: SET_SELECTION_ANIMATION_STATE, state })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectionAnimationOverlay);
