const pairs = [
  ["Weyes Blood", "Front Row Seat To Earth"],

  [
    "David Bowie",
    "The Rise and Fall of Ziggy Stardust And The Spiders From Mars"
  ],
  ["Miles Davis", "Seven Steps To Heaven"],
  ["Kamasi Washington", "The Epic"],

  ["J Dilla", "Donuts"],
  ["Pavement", "Wowee Zowee"],
  ["Frank Sinatra", "Songs for Young Lovers"],
  ["Willie Colon", "The Hustler"],
  ["Martha Reeves and the Vandellas", "Dancing in the street"],
  ["Aretha Franklin", "Young, gifted and black"],
  ["Yma Sumac", "Legend of the Jivaro"],
  ["Purple Mountains", "Purple Mountains"],
  ["Flying Lotus", "Flamagra"],
  ["The War on Drugs", "Lost in the dream"],
  ["Aphex Twin", "Syro"],
  ["Kurt Vile", "Smoke Ring for my halo"],
  ["Father John Misty", "I love you, honeybear"],
  ["Oneohtrix Point Never", "Replica"],
  ["Blood Orange", "Cupid Deluxe"],
  ["FKA Twigs", "LP1"],
  ["Angel Olsen", "All Mirrors"],
  ["The Smiths", "The Queen Is Dead"],

  ["Sufjan Stevens", "Carrie  & Lowell"],
  ["Big Thief", "U.F.O.F."],
  ["Johnny Cash", "Hello, I'm Johnny Cash"]
];
export const tryRecords = pairs.map(pair => ({
  attributes: {
    name: pair[1],
    artistName: pair[0]
  }
}));
