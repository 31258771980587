import React, { useState } from "react";
import styles from "./push-button-switch.module.scss";
import { TouchableOpacity } from "react-native-web";

export default function PushButtonSwitch(props) {
  const { states, code } = props;

  const [position, setPosition] = useState(
    states.findIndex(state => state.code === code)
  );

  if (!code || !states || states.length === 0) return null;

  function onPress() {
    let newPosition = null;
    setPosition(position => {
      newPosition = position + 1;
      if (newPosition > states.length - 1) newPosition = 0;
      props.onChange && props.onChange(states[newPosition]);
      return newPosition;
    });
  }

  return (
    <TouchableOpacity
      onPress={onPress}
      className={styles.container}
      style={props.containerStyle}
    >
      {states[position].icon()}
    </TouchableOpacity>
  );
}
