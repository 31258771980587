import React from "react";
import connect from "react-redux/es/connect/connect";
import {
  SEARCH_UI_PHASE_ARTIST_SELECTED,
  SEARCH_UI_PHASE_RESULTS
} from "../../reducers/search-reducer";
import styles from "./results.module.scss";
import _ from "lodash";
import ThickSpineRecord from "../ThickSpineRecord/thick-spine-record";
import Artist from "../Artist/artist";
import {
  clearArtistSelection,
  searchResultArtistClicked,
  setSearchUIPhase
} from "../../actions/search-actions";
import { jlog } from "../../helpers";
import moment from "moment";
import Shelf from "../Shelf/shelf";
class Results extends React.Component {
  artistResults(artist) {
    const { backPressed, results, showBack } = this.props;
    if (!artist) return null;
    const records = artist.relationships.albums.data;

    records.sort(
      (a, b) =>
        moment(b.attributes.releaseDate).unix() -
        moment(a.attributes.releaseDate).unix()
    );
    //records.sort((a,b)=>a.attributes.name>b.attributes.name)
    const fullAlbums = records.filter(
      record =>
        record.attributes.isSingle === false &&
        record.attributes.isCompilation === false
    );
    const singles = records.filter(
      record => record.attributes.isSingle === true
    );
    const hasSingles = singles && singles.length > 0;
    return (
      <div className={styles.artistContainer}>
        {/* {results && showBack && (
          <div onClick={backPressed} className={styles.backButton}>
            <i className="fa fa-chevron-left" />
            &nbsp;back
          </div>
        )} */}
        <div className={styles.artistRecordsContainer}>
          <div className={styles["header"]}>
            Albums
            {/* albums ({`${fullAlbums.length}`}) */}
          </div>
          <Shelf
            containerStyle={{
              gridTemplateColumns: "repeat(auto-fill, minmax(160px,1fr))"
            }}
            recordProps={{ playOnHover: true }}
            records={fullAlbums}
            recordClass="artist"
          />
        </div>
        <div className={styles.artistRecordsContainer}>
          {hasSingles && (
            <div className={styles["header"]}>
              Singles
              {/* singles ({`${singles.length}`}) */}
            </div>
          )}
          <Shelf
            containerStyle={{
              gridTemplateColumns: "repeat(auto-fill, minmax(160px,1fr))"
            }}
            recordProps={{ playOnHover: true, includeSingles: true }}
            records={singles}
            recordClass="artist"
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      uiPhase,
      artists,
      albums,
      selectedArtist,
      results,
      searchQuery,
      searchInProgress
    } = this.props;
    // if (
    //   ![SEARCH_UI_PHASE_RESULTS, SEARCH_UI_PHASE_ARTIST_SELECTED].includes(
    //     uiPhase
    //   )
    // )
    //   return null;
    if (searchInProgress)
      return (
        <div className={styles.searching}>
          searching Apple Music for '{searchQuery}' ...
        </div>
      );
    const showArtistResults = true; //uiPhase === SEARCH_UI_PHASE_ARTIST_SELECTED;
    if (selectedArtist) {
      return (
        <div className={styles.container}>
          {/* <div className={styles.queryHeader}>Results for '{searchQuery}':</div> */}
          {this.artistResults(selectedArtist)}
        </div>
      );
    }
    //if (!results) return null;
    const showTitles =
      artists && artists.length > 0 && albums && albums.length > 0;

    return (
      <div className={styles.container}>
        {/* <div className={styles.queryHeader}>Results for '{searchQuery}':</div> */}
        <div className={styles.artistsContainer}>
          {showTitles && <div className={styles.header}>Artists</div>}
          <div className={styles.artistsList}>
            {artists &&
              artists.map(artist => (
                <Artist
                  onClick={() => this.props.artistClicked(artist)}
                  artist={artist}
                />
              ))}
          </div>
        </div>
        <div className={styles.shelf}>
          <div className={styles.header}>Records</div>
          <Shelf
            recordProps={{ playOnHover: true }}
            records={albums}
            recordClass={"search-results"}
            containerStyle={{
              gridTemplateColumns: "repeat(auto-fit, minmax(80px,1fr))"
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    searchQuery: state.search.text,
    selectedArtist: state.search.selectedArtist,
    results: state.search.results,
    showBack: state.search.showBack,
    artists: _.get(state.search, "results.artists.data"),
    albums: _.get(state.search, "results.albums.data"),
    uiPhase: state.search.uiPhase,
    searchInProgress: state.search.searchInProgress
  };
};

const mapDispatchToProps = dispatch => {
  return {
    backPressed: () => {
      dispatch(setSearchUIPhase(SEARCH_UI_PHASE_RESULTS));
      dispatch(clearArtistSelection());
    },
    artistClicked: artist => dispatch(searchResultArtistClicked(artist))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Results);
