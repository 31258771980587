import React, { useState } from "react";
import {
  FlatList,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity
} from "react-native";
import styles from "./onboarding.module.scss";

const screenWidth = window.document.body.clientWidth;
const Step1 = props => {
  return (
    <div className={styles.step} style={{ width: screenWidth }}>
      step 1<TouchableOpacity onPress={() => {}}>continue</TouchableOpacity>
    </div>
  );
};

const Step2 = props => {
  return (
    <div className={styles.step} style={{ width: screenWidth }}>
      step 2
    </div>
  );
};

const DATA = [
  {
    id: 0,
    title: "Step 1",
    component: Step1
  },
  {
    id: 1,
    title: "Step 2",
    component: Step2
  }
];

const Item = ({ item, onPress, style }) => (
  <TouchableOpacity onPress={onPress} style={[styles.item, style]}>
    <Text style={styles.title}>{item.title}</Text>
  </TouchableOpacity>
);

const Onboarding = props => {
  const [selectedId, setSelectedId] = useState(null);

  const renderItem = ({ item }) => {
    return item.component(props);
  };

  return (
    <SafeAreaView style={styles.container}>
      <FlatList
        horizontal={true}
        data={DATA}
        renderItem={renderItem}
        keyExtractor={item => item.id}
        extraData={selectedId}
      />
    </SafeAreaView>
  );
};

export default Onboarding;
