import React, { useState, useEffect, useRef } from "react";
import connect from "react-redux/es/connect/connect";
import { TouchableWithoutFeedback } from "react-native-web";
import { recordPlayPressed } from "../../actions/playback-actions";
import {
  clearSelectedRecord,
  getCatalogAlbumWithArtistNameAndAlbumName,
  loadTracksIfNeeded
} from "../../actions/record-actions";
import {
  areRecordsTheSame,
  calculatePlaybackStateName,
  sizedUrl,
  slugForRecord,
  uiVariables,
  setCssVariable,
  randomImageUrl
} from "../../helpers";
import styles from "./big-record.module.scss";
import _ from "lodash";
import moment from "moment";
import { TURN_BIG_RECORD_TO_SIDE } from "../../reducers/record-reducer";
import MusicbrainzCoverArt from "../MusicbrainzCoverArt/musicbrainz-cover-art";
import CoverHud from "../CoverHud/cover-hud";
import BigRecordDetails from "../BigRecordDetails/big-record-details";
import { isContainedInIosNativeApp } from "../../helpers/native-ios-mk-implementation";
import { setPaletteBasedOnRecord } from "../../actions/ui-actions";
import { SELECTION_ANIMATION_DURATION } from "../SelectionAnimationOverlay/selection-animation-overlay";
import { flag, Flags } from "../../helpers/flags";
import { BIG_RECORD_IMAGE_WIDTH_HEIGHT } from "../../helpers/constants";
var classNames = require("classnames");

const PRESS_ANIMATION_DURATION = 150;

function getRandomId() {
  return Math.floor(Math.random() * Math.floor(1000000));
}

function BigRecord(props) {
  const {
    style = {},
    imgStyle = {},
    klass = "default",
    onImageLoadFailure,
    turnRecordOver,
    showHoverText = false,
    showCoverHud = true,
    includeSingles = false
  } = props;
  const [record, setRecord] = useState(props.record);
  const [coverUiState, setCoverUiState] = useState("unpressed");

  const [frontAnimationClass, setFrontAnimationClass] = useState(null);
  const [backAnimationClass, setBackAnimationClass] = useState(null);
  const [coverHover, setCoverHover] = useState(false);
  const [layoutRect, setLayoutRect] = useState(null);
  const [domId, setDomId] = useState(getRandomId());
  const [side, setSide] = useState(props.bigRecordSide);
  const containerRef = React.createRef();
  const coverHoverRef = useRef(coverHover);

  useEffect(() => {
    getLayoutInfo();
  }, []);

  useEffect(() => {
    if (props.bigRecordSide !== 0) {
      setFrontAnimationClass(styles.turnOut);
      setTimeout(() => {
        setSide(1);
        setBackAnimationClass(styles.turnInBack);
      }, uiVariables.turnOverDuration.value);
    } else {
      if (side !== 0) {
        // going from turnedOver true to false, not false to false
        setBackAnimationClass(styles.turnOutBack);
        setTimeout(() => {
          setSide(0);
          setFrontAnimationClass(styles.turnIn);
        }, uiVariables.turnOverDuration.value);
      }
    }
  }, [props.bigRecordSide]);

  function getLayoutInfo() {
    const element = document.getElementById(domId);
    if (element) {
      const rect = element.getBoundingClientRect();
      setLayoutRect(rect);
    }
  }

  useEffect(() => {
    setRecord(props.record);
    props.loadTracksIfNeeded(props.record);
  }, [props.record.id]);

  useEffect(() => {
    if (side > 0) {
      getLayoutInfo();

      window.addEventListener("resize", getLayoutInfo);
      return () => {
        window.removeEventListener("resize", getLayoutInfo);
      };
    }
  }, [side]);

  useEffect(() => {
    coverHoverRef.current = coverHover;
  }, [coverHover]);

  useEffect(() => {
    const rawImageUrl =
      props.coverArtImage || _.get(record.attributes, "artwork.url");
    if (!rawImageUrl) return;
    const imageUrl = flag(Flags.useRandomRecordData)
      ? randomImageUrl(record)
      : sizedUrl(rawImageUrl, BIG_RECORD_IMAGE_WIDTH_HEIGHT);
    const img = document.getElementById("main");
    if (img) img.src = imageUrl;
  }, [record.id, props.coverArtImage, side]);

  async function onCoverHoverEnter() {
    setCoverHover(true);
  }

  function onCoverHoverExit() {
    setCoverHover(false);
  }

  async function updateRecordWithCatalogAlbum(record) {
    let newRecord = props.slugToRecordMap[slugForRecord(record)];
    if (newRecord) {
      return newRecord;
    } else {
      const catalogAlbum = await getCatalogAlbumWithArtistNameAndAlbumName(
        record.attributes.artistName,
        record.attributes.name
      );
      if (!catalogAlbum) return;
      catalogAlbum.catalogAlbumId = _.get(catalogAlbum, "id");
      props.addRecordToMap(catalogAlbum);
      newRecord = { ...record, catalogAlbumId: catalogAlbum.id };
      setRecord(newRecord);
      return newRecord;
    }
  }
  async function recordTapped(event, record) {
    setCoverHover(false);
    turnRecordOver(record, domId);
  }

  const a = record.attributes || {};
  const source = _.get(record, "extra.source");
  let sourceStyle = {};

  const isInAppleMusic = !!(a.artwork || props.coverArtImage);
  const title =
    showHoverText &&
    `${a.artistName}\r\n${a.name}\r\n${a.recordLabel}\r\n${moment(
      a.releaseDate,
      "YYYY-MM-DD"
    ).format("YYYY")}`;

  function onPressIn() {
    if (coverUiState !== "selected") {
      setCoverUiState("pressed");
    }
  }

  function onPressOut() {
    setCoverUiState("unpressed");
  }

  let coverTransform = null;
  let coverOpacity = null;
  let coverZIndex = null;

  switch (coverUiState) {
    case "pressed":
      setCssVariable(
        "recordAnimationDuration",
        `${PRESS_ANIMATION_DURATION}ms`
      );
      coverTransform = isContainedInIosNativeApp()
        ? "scale(0.95)"
        : "scale(0.99)";
      //coverOpacity = "0.8";
      coverZIndex = 0;
      break;
    case "unpressed":
      setCssVariable(
        "recordAnimationDuration",
        `${PRESS_ANIMATION_DURATION}ms`
      );

      coverTransform = "scale(1)";
      //coverOpacity = "1";
      coverZIndex = 0;

      break;

    default:
      break;
  }

  let coverStyle = {
    ...props.coverStyle,
    transform: coverTransform,
    opacity: coverOpacity,
    zIndex: coverZIndex
  };

  const imageWidth = parseInt(_.get(style, "width", 1000));
  // if (a.isSingle && !includeSingles) return null;

  let playbackStateStyle;
  if (isContainedInIosNativeApp()) {
    playbackStateStyle =
      //props.playbackStateName &&
      props.isPlaying &&
      !props.currentlyPreviewingRecord &&
      props.playbackStateName !== "playing"
        ? {
            opacity: 1
          }
        : {
            animationDuration: "0.5s",
            transition: "opacity 1s 5s",
            opacity: 0
          };
  } else {
    playbackStateStyle =
      props.playbackStateName &&
      //props.isPlaying &&
      //!props.currentlyPreviewingRecord &&
      (props.playbackStateName !== "playing" || coverHover)
        ? {
            transition: null,
            opacity: 1
          }
        : {
            animationDuration: "0.5s",
            transition: "opacity 1s 5s",
            opacity: 0
          };
  }
  if (!props.currentlyPlayingRecord)
    playbackStateStyle = {
      transition: null,
      opacity: 0
    };
  return side === 1 && layoutRect ? (
    <BigRecordDetails
      domId={domId}
      {...(props.recordDetailsProps || {})}
      availableImageWidth={imageWidth}
      klass={klass}
      animationClass={backAnimationClass}
      width={layoutRect && layoutRect.width}
      layoutRect={layoutRect}
      record={record}
    />
  ) : (
    <div
      id={domId}
      ref={containerRef}
      className={classNames(styles[klass], frontAnimationClass)}
      style={{ ...style, ...sourceStyle }}
    >
      <div style={playbackStateStyle} className={styles["playback-state"]}>
        {props.playbackStateName}
      </div>
      <div
        id={record.id}
        className={styles[`cover`]}
        style={coverStyle}
        onMouseEnter={onCoverHoverEnter}
        onMouseLeave={onCoverHoverExit}
      >
        <TouchableWithoutFeedback
          delayPressIn={0}
          activeOpacity={0.9}
          onPressIn={() => onPressIn()}
          onPressOut={() => onPressOut()}
          onPress={e => recordTapped(e, record)}
          onLongPress={e => props.longPressed(e, record, false)}
        >
          {isInAppleMusic ? (
            <img
              id={"main"}
              draggable={false}
              style={{ ...imgStyle, ...{ background: `url(backgroundImage)` } }}
              title={title ? title : undefined}
              onLoad={() => {
                console.log("onload");
                props.onImageLoad && props.onImageLoad();
              }}
            />
          ) : (
            <MusicbrainzCoverArt
              record={record}
              size={style.width}
              artwork={"Front"}
              onFail={() => {
                onImageLoadFailure && onImageLoadFailure(record);
              }}
            />
          )}
        </TouchableWithoutFeedback>
        {showCoverHud && (
          <CoverHud active={coverHover} values={layoutRect} record={record} />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  let { record } = ownProps;
  if (!record.attributes)
    return { record: { href: `/v1/catalog/us/albums/${record.id}` } };
  const { recentsShelfIndex, labelToArtistMap } = state.record;
  const _labelArtists = labelToArtistMap[record.attributes.recordLabel];
  const labelArtistsFiltered = (_labelArtists || []).filter(artist => {
    return _.toLower(artist) !== _.toLower(record.attributes.artistName);
  });

  const labelArtists =
    labelArtistsFiltered && labelArtistsFiltered.length > 0
      ? _.join(_.slice(_labelArtists, 0, 1), ", ")
      : null;

  const shelf = state.record.shelves[recentsShelfIndex];
  const addedRecords = shelf.records;
  const isAdded = addedRecords.reduce((acc, addedRecord) => {
    return areRecordsTheSame(record, addedRecord) | acc;
  }, false);
  const musicbrainzId = _.get(record, "attributes.musicbrainzId");
  const idRating = state.record.ratingsMap[record.id];
  const catalogAlbumIdRating = state.record.ratingsMap[record.catalogAlbumId];
  const originalIdRating = state.record.ratingsMap[record.originalId];
  const rating = idRating || catalogAlbumIdRating || originalIdRating;
  const isTurnedOver =
    _.get(state.record.turnedOverRecord, "recordId") === record.id;
  const isSelected = _.get(state, "record.selected.record.id") === record.id;
  return {
    isSelected,
    selectedRecord: state.lightweightRecord.selectedRecord,
    slugToRecordMap: state.record.slugToRecordMap,
    isTurnedOver,
    bigRecordSide: state.lightweightRecord.bigRecordSide || 0,
    coverArtImage: state.record.recordIdToCoverArtImageMap[record.id],
    turnedOverRecordId: _.get(
      state.lightweightRecord.turnedOverRecord,
      "recordId"
    ),
    turnedOverDomId: _.get(state.lightweightRecord.turnedOverRecord, "domId"),
    previewReady: state.ui.previewReady,
    labelArtists,
    rating,
    originalIdRating,
    idRating,
    catalogAlbumIdRating,
    musicbrainzOnly:
      musicbrainzId && state.record.mbidToMusicbrainzOnlyMap[musicbrainzId],
    palette: state.ui.palette,
    isAdded,
    playbackStateName: calculatePlaybackStateName(state),
    loaded: !!record.attributes,
    record,
    recentsShelfIndex,
    currentlyPlayingRecord: state.playback.currentlyPlayingRecord,
    currentlyPreviewingRecord: state.playback.currentlyPreviewingRecord,
    isPlaying:
      _.get(state, "playback.currentlyPlayingRecord.id") &&
      _.get(state, "playback.currentlyPlayingRecord.id") === record.id,
    isPreviewing:
      record.id &&
      _.get(state, "playback.currentlyPreviewingRecord.id") === record.id,
    previewStatus: state.playback.previewStatus
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadTracksIfNeeded: record => {
      dispatch(loadTracksIfNeeded(record));
    },
    dismiss: () => {
      dispatch(clearSelectedRecord());
      setTimeout(
        () => dispatch({ type: TURN_BIG_RECORD_TO_SIDE, side: 0 }),
        SELECTION_ANIMATION_DURATION
      );
    },
    longPressed: (event, record) => {
      event.stopPropagation();
      dispatch(setPaletteBasedOnRecord(record));

      dispatch(recordPlayPressed(record, false));
    },

    turnRecordOver: () => {
      dispatch({ type: TURN_BIG_RECORD_TO_SIDE, side: 1 });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BigRecord);
