import React from "react";
import connect from "react-redux/es/connect/connect";

function DebugPlayground(props) {
  const { log } = props;
  return (
    <div style={{ margin: "5em" }}>
      <div>deb</div>
      <img
        src={""}
        style={{
          width: 100,
          height: 100,
          border: 7,
          margin: 0,
          padding: 0
        }}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DebugPlayground);
