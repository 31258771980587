import React, { useEffect, useState, useRef } from "react";
import styles from "./slider.module.scss";
import {
  PanResponder,
  View,
  Text,
  TouchableWithoutFeedback
} from "react-native-web";
import { jlog } from "../../../helpers";
import _ from "lodash";

const VELOCITY_THRESHOLD = 0.1;
const handlePadding = 2;

export default function Slider(props) {
  // consts
  const {
    height = 26,
    width = 60,
    handleWidth = 20,
    steps = [],
    initialStepCode
  } = props;

  const maxX = width - handlePadding * 2 - handleWidth;
  const stepWidth = maxX / (steps.length - 1);
  const anchors = [handlePadding];
  for (let i = 1; i < steps.length; ++i) {
    anchors.push(i * stepWidth);
  }
  anchors.push(maxX - handlePadding);

  let initialTranslateX = null;
  let initialStepIndex = null;
  for (let i = 0; i < steps.length; ++i) {
    if (steps[i][0] === initialStepCode) {
      initialStepIndex = i;
      initialTranslateX = anchors[i];
    }
  }

  // refs + state vars
  const [sliding, setSliding] = useState(false);
  const translateXRef = useRef(initialTranslateX);
  const [translateX, setTranslateX] = useState(initialTranslateX);
  const [stepIndex, setStepIndex] = useState(initialStepIndex);

  //effects
  useEffect(() => {
    steps.forEach((step, i) => {
      if (steps[i][0] === initialStepCode) {
        setStepIndex(i);
      }
    });
  }, [initialStepCode]);

  useEffect(() => {
    translateXRef.current = translateX;
  }, [translateX]);

  useEffect(() => {
    setTranslateX(anchors[stepIndex]);
    props.onChange && setImmediate(() => props.onChange(steps[stepIndex]));
  }, [stepIndex]);

  // panresponder
  const initialPanX = useRef(null);
  const logPanEvents = true;
  let panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponderCapture: () => true,
      onMoveShouldSetPanResponderCapture: () => true,
      onStartShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponder: () => true,
      onPanResponderGrant: (evt, gestureState) => {
        // start to respond
        if (logPanEvents) jlog({ onPanResponderGrant: gestureState });
        setSliding(true);

        initialPanX.current = translateXRef.current;
        return false;
      },
      onPanResponderMove: (evt, gestureState) => {
        setSliding(true);
        // don't respond to tiny movements (ie a tap)
        //if (Math.abs(gestureState.dx) < 1) return false;
        // start to respond
        if (logPanEvents) jlog({ onPanResponderMove: gestureState });
        const x = initialPanX.current + gestureState.dx;
        let n = Math.min(x, maxX);
        if (n < handlePadding) n = handlePadding;
        const nearestAnchorIndex = nearestAnchorIndexToX(x);
        setStepIndex(nearestAnchorIndex);
        return false;
      },
      onPanResponderRelease: (evt, gestureState) => {
        setSliding(false);
        if (logPanEvents) jlog({ onPanResponderRelease: gestureState });
        initialPanX.current = null;
        return false;
      }
    })
  );

  //styles
  const containerStyle = {
    transition: "all 0.5s",
    height,
    width,
    borderRadius: height / 2,
    border: `1px solid ${props.color}`
  };

  const handleStyle = {
    transition: "all 0.1s",
    opacity: 0.7,
    width: handleWidth,
    padding: 2,
    backgroundColor: props.color,
    transform: `translate(${translateX}px,2px)`,
    borderRadius: height / 2,
    userSelect: "none"
  };

  function nearestAnchorIndexToX(x) {
    let nearestIndex = null;
    const anchorIndexToLeft = _.findLastIndex(anchors, a => a < x);
    const anchorIndexToRight = anchorIndexToLeft + 1;
    if (anchorIndexToLeft === -1) {
      nearestIndex = 0;
    } else if (anchorIndexToRight === anchors.length) {
      nearestIndex = anchorIndexToRight - 1;
    } else {
      const midpoint =
        (anchors[anchorIndexToLeft] + anchors[anchorIndexToRight]) / 2;
      nearestIndex = x > midpoint ? anchorIndexToRight : anchorIndexToLeft;
    }
    if (nearestIndex < 0) nearestIndex = 0;
    if (nearestIndex > steps.length - 1) nearestIndex = steps.length - 1;
    return nearestIndex;
  }

  function onBackgroundClick(e) {
    var rect = e.target.getBoundingClientRect();
    var x = e.clientX - rect.left; //x position within the element.
    const nearestIndex = nearestAnchorIndexToX(x);
    setStepIndex(nearestIndex);
  }

  // render
  return (
    <div>
      <div
        style={{
          color: props.color,
          transition: "opacity 0.5s",
          alignSelf: "center",
          paddingBottom: 2,
          justifySelf: "center",
          fontSize: 12,
          opacity: sliding ? 1 : 0
        }}
      >
        {steps[stepIndex] && steps[stepIndex][1]}
      </div>
      <TouchableWithoutFeedback
      //onPress={onBackgroundClick}
      >
        <div className={styles.container} style={containerStyle}>
          <View {...panResponder.current.panHandlers} style={handleStyle}>
            <Text
              style={{
                alignSelf: "center",
                marginTop: -1,
                paddingBottom: 2,
                justifySelf: "center",
                fontSize: 12,
                color: props.labelColor
              }}
            >
              {steps[stepIndex] ? steps[stepIndex][0] : "?"}
            </Text>
          </View>
        </div>
      </TouchableWithoutFeedback>
    </div>
  );
}
