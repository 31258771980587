import {
  jlog,
  mkplayer,
  slugForArtistNameAndAlbumName,
  areRecordsTheSame
} from "../helpers";
import _ from "lodash";

export const PLAYBACK_STOPPED = "PLAYBACK_STOPPED";
export const PLAYBACK_STARTED = "PLAYBACK_STARTED";
export const PLAYBACK_PAUSED = "PLAYBACK_PAUSED";
export const MEDIA_ITEM_CHANGED = "MEDIA_ITEM_CHANGED";
export const NATIVE_MEDIA_ITEM_CHANGED = "NATIVE_MEDIA_ITEM_CHANGED";
export const PLAYBACK_PROGRESS_CHANGED = "PLAYBACK_PROGRESS_CHANGED";
export const PLAYBACK_TIME_CHANGED = "PLAYBACK_TIME_CHANGED";
export const PLAYBACK_STATE_CHANGED = "PLAYBACK_STATE_CHANGED";
export const PREVIEW_STARTED = "PREVIEW_STARTED";
export const PREVIEW_STOPPED = "PREVIEW_STOPPED";
export const UPDATE_PREVIEW_STATUS = "UPDATE_PREVIEW_STATUS";
export const TRANSITION_PREVIEW_TO_PLAY = "TRANSITION_PREVIEW_TO_PLAY";
export const SET_OVERPLAYER_IN_DOM_STATE = "SET_OVERPLAYER_IN_DOM_STATE";
export const SET_OVERPLAYER_SLID_UP_STATE = "SET_OVERPLAYER_SLID_UP_STATE";
export const SET_NATIVE_PLAYBACK_STATE = "SET_NATIVE_PLAYBACK_STATE";
export const SET_IS_PLAYING = "SET_IS_PLAYING";
export const CLEAR_PLAYBACK = "CLEAR_PLAYBACK";
export const SET_CURRENTLY_PLAYING_RECORD = "SET_CURRENTLY_PLAYING_RECORD";

const { PlaybackStates } = window.MusicKit || {};
const playbackStateNames = PlaybackStates && {
  [PlaybackStates.complete]: "complete",
  [PlaybackStates.ended]: "ended",
  [PlaybackStates.playing]: "playing",
  [PlaybackStates.loading]: "loading",
  [PlaybackStates.none]: "none",
  [PlaybackStates.paused]: "paused",
  [PlaybackStates.seeking]: "seeking",
  [PlaybackStates.stalled]: "stalled",
  [PlaybackStates.stopped]: "stopped",
  [PlaybackStates.waiting]: "waiting"
};

export const NativePlaybackStateNames = {
  0: "stopped",
  1: "playing",
  2: "stalled",
  3: "paused"
};

const initialState = {
  currentlyPlayingRecord: null,
  currentlyPlayingTrack: null,
  currentlyPreviewingRecord: null,
  player: null,
  progress: null,
  mediaItem: null,
  overPlayerInDom: false,
  overPlayerSlidUp: true,
  setIsMouseOverPreviewingRecord: false
};

const PlaybackReducer = (state = initialState, action) => {
  switch (action.type) {
    case "setIsMouseOverPreviewingRecord": {
      return {
        ...state,
        mouseIsOverPreviewingRecord: action.bool,
        mouseX: action.x,
        mouseY: action.y
      };
    }
    case SET_NATIVE_PLAYBACK_STATE: {
      return { ...state, nativePlaybackState: action.playbackState };
    }
    case SET_OVERPLAYER_IN_DOM_STATE: {
      return { ...state, overPlayerInDom: action.active };
    }
    case SET_OVERPLAYER_SLID_UP_STATE: {
      return { ...state, overPlayerSlidUp: action.active };
    }
    case PLAYBACK_PAUSED: {
      return { ...state, currentlyPlayingRecord: null };
    }
    case PLAYBACK_STOPPED: {
      return {
        ...state,
        currentlyPlayingRecord: null,
        playbackStateName: null
      };
    }
    case CLEAR_PLAYBACK: {
      return {
        ...state,
        currentlyPlayingRecord: null,
        currentlyPlayingTrack: null
      };
    }
    case SET_CURRENTLY_PLAYING_RECORD:
      return { ...state, currentlyPlayingRecord: action.record };
    case PLAYBACK_STARTED: {
      return {
        ...state,
        //player: action.player,
        currentlyPlayingRecord: action.record,
        currentlyPlayingTrack: action.track
      };
    }
    case PREVIEW_STARTED: {
      return {
        ...state,
        currentlyPreviewingRecord: action.record
      };
    }
    case UPDATE_PREVIEW_STATUS: {
      return {
        ...state,
        previewStatus: action.status
      };
    }
    case PREVIEW_STOPPED: {
      const record = action.record;

      if (!record)
        return {
          ...state,
          currentlyPreviewingRecord: null,
          mediaItem: null,
          queue: null
        };
      if (
        record.id === _.get(state, "currentlyPreviewingRecord.id") ||
        record.id === _.get(state, "mediaItem.id") ||
        record.id === _.get(state, "mediaItem._container.id")
      )
        return {
          ...state,
          currentlyPreviewingRecord: null,
          mediaItem: null,
          queue: null
        };
      else return state;
    }
    case TRANSITION_PREVIEW_TO_PLAY: {
      return {
        ...state,
        currentlyPlayingRecord: state.currentlyPreviewingRecord,
        currentlyPreviewingRecord: null
      };
    }

    case PLAYBACK_PROGRESS_CHANGED: {
      const { event } = action;
      return {
        ...state,
        progress: event.progress
      };
    }
    case NATIVE_MEDIA_ITEM_CHANGED: {
      let { mediaItem, queue } = action;
      queue = {
        ...mkplayer().queue,
        ...queue
      };
      return {
        ...state,
        mediaItem,
        queue
      };
    }
    case MEDIA_ITEM_CHANGED: {
      const { mediaItem } = action;
      if (!mediaItem) return state;
      const queue = {
        ...mkplayer().queue
      };
      return {
        ...state,
        mediaItem,
        queue
      };
    }
    case PLAYBACK_TIME_CHANGED: {
      /* ex:
          {
            "currentPlaybackDuration": 84,
            "currentPlaybackTime": 16,
            "currentPlaybackTimeRemaining": 68
          }
       */
      const { event } = action;
      return {
        ...state,
        ...event
      };
    }
    case PLAYBACK_STATE_CHANGED: {
      const { playbackState } = action;
      return {
        ...state,
        playbackState: playbackState,
        playbackStateName: playbackStateNames[playbackState]
      };
    }
    default:
      return state;
  }
};

export default PlaybackReducer;
