import React, { useState, useEffect } from "react";
import styles from "./selected-record.module.scss";
import _ from "lodash";
import connect from "react-redux/es/connect/connect";
import BigRecord from "../BigRecord/big-record";
import {
  areRecordsTheSame,
  bestRecord,
  slugForRecord,
  notchHeight,
  isPlaylist
} from "../../helpers";
import Shelf from "../Shelf/shelf";
import {
  loadDetailedInfoForRecord,
  loadDiscographyForRecord,
  clearSelectedRecord,
  loadRecordIfNeeded
} from "../../actions/record-actions";
import moment from "moment";
import { isContainedInIosNativeApp } from "../../helpers/native-ios-mk-implementation";
import { ScrollView, TouchableOpacity } from "react-native-web";
import {
  AnimationStates,
  SET_SELECTION_ANIMATION_STATE,
  TURN_BIG_RECORD_TO_SIDE
} from "../../reducers/record-reducer";
import { constant, Constants } from "../../helpers/flags";
import { SELECTION_ANIMATION_DURATION } from "../SelectionAnimationOverlay/selection-animation-overlay";

var md5 = require("md5");

function SelectedRecord(props) {
  const {
    clientHeight,
    clientWidth,
    klass = "bigSwipeCover",
    playingRecord,
    dismiss,
    isContainedInCatalyst
  } = props;
  const heightAdjust = isContainedInCatalyst ? 140 : 90;
  const imgHeightWidth = Math.min(
    clientHeight - heightAdjust,
    clientWidth - 14
  );
  const coverStyle = {
    //width: imgHeightWidth
  };
  const imgStyle = {
    width: imgHeightWidth
  };
  const [discographyRecords, setDiscographyRecords] = useState([]);
  const [labelRecords, setLabelRecords] = useState([]);
  const [record, setRecord] = useState(props.record);
  const [labelArea, setLabelArea] = useState(null);
  const [labelDates, setLabelDates] = useState(null);
  const [byArtistNameOnly, setByArtistNameOnly] = useState(false);
  const [labelName, setLabelName] = useState(
    _.get(record, "attributes.recordLabel")
  );
  const [releaseDate, setReleaseDate] = useState(
    _.get(record, "attributes.releaseDate")
  );

  useEffect(() => {
    props.record && setRecord(props.record);
  }, [props.record && props.record.id]);

  useEffect(() => {
    setLabelName(_.get(record, "attributes.recordLabel"));
  }, [_.get(props.record, "attributes.recordLabel")]);

  useEffect(() => {
    const detailedInfo = props.recordIdToDetailedInfoMap[_.get(record, "id")];
    if (detailedInfo) {
      const _byArtistNameOnly = detailedInfo.by_artist_name_only;
      setByArtistNameOnly(_byArtistNameOnly);
      const labelRecords = detailedInfo.contemporaneous_original_label_releases;
      if (!labelRecords) return;
      const labelRecordsFiltered = labelRecords
        .filter(r => !props.recordSlugsWithNoImages[slugForRecord(r)])
        .filter(r => {
          return !areRecordsTheSame(r, record);
        });
      setLabelRecords(_.slice([...labelRecordsFiltered].reverse(), 0, 20));
      setLabelName(detailedInfo.earliest_release.label_name);
      if (!_byArtistNameOnly) {
        setReleaseDate(detailedInfo.earliest_release.release_date);
      }
      setLabelArea(detailedInfo.earliest_release.label_area);

      if (detailedInfo.earliest_release.label_begin_year) {
        let labelDates = `${detailedInfo.earliest_release.label_begin_year}-`;
        const labelDates2 = detailedInfo.earliest_release.label_end_year
          ? detailedInfo.earliest_release.label_end_year
          : "present";
        setLabelDates(`${labelDates}${labelDates2}`);
      }
    } else {
      //setLabelRecords(null)
      //  setLabelName(null)
      //  setReleaseDate(null)
      //  setLabelArea(null)
    }
  }, [
    record && record.id,
    record && md5(props.recordIdToDetailedInfoMap[_.get(record, "id")] || ""),
    props.recordSlugsWithNoImages
  ]);

  useEffect(() => {
    if (!props.record) return;
    if (isPlaylist(record)) return;
    // props.loadRecordIfNeeded(props.record, false, _record => {
    props.loadDetailedInfoForRecord(props.record);
    props.loadDiscographyForRecord(props.record);
    // });
  }, [labelName, props.record && props.record.id]);

  useEffect(() => {
    let records = props.recordSlugToDiscographyMap[slugForRecord(record)];
    setDiscographyRecords(records);
  }, [props.recordSlugToDiscographyMap[slugForRecord(record)]]);

  const targetWidth = Math.min(
    225,
    props.clientWidth / 2.25,
    props.clientHeight / 2.25
  );
  const year = releaseDate && moment(releaseDate, "YYYY-MM-DD").format("YYYY");
  if (!record) return null;
  const marginTop = notchHeight(clientHeight);
  return (
    <div
      className={styles[klass]}
      style={{
        top: constant(Constants.offsetInterfaceBy),
        marginTop,
        marginBottom: 100,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start"
      }}
    >
      <ScrollView>
        <TouchableOpacity
          style={{ display: "flex" }}
          onPress={isContainedInIosNativeApp() ? null : props.dismiss}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              minHeight: imgHeightWidth
              // marginTop: 8
            }}
          >
            <div
              style={{
                position: "relative",
                perspective: "1000px"
              }}
            >
              <BigRecord
                urlSize={1500}
                recordDetailsProps={{
                  adaptToIntrinsicHeight: true,
                  showDiscography: false,
                  showLabelRecords: false,
                  showCoverArt: false,
                  showShareLink: false,
                  showText: true
                }}
                klass={"big-picked-record"}
                showCoverHud={true}
                style={coverStyle}
                imgStyle={imgStyle}
                record={record}
                onImageLoad={() => {
                  setTimeout(() =>
                    props.setSelectionAnimationState(
                      AnimationStates.OutComplete,
                      60
                    )
                  );
                }}
              />
            </div>
          </div>
        </TouchableOpacity>

        <div className={styles.contextRecords}>
          <div className={styles.contextRecord}>
            {labelRecords && labelRecords.length > 0 && (
              <div>
                <div className={styles.catalogShelf}>
                  <Shelf
                    recordClass={"library-bold-embedded-in-big"}
                    excludeRecords={[record]}
                    recordProps={{
                      allowTurnOver: true,
                      playOnHover: true,
                      showCoverHud: true,
                      ignoreVisibility: true
                    }}
                    sortOptionIndex={0}
                    sortOptions={[
                      {
                        sortRecordsBy: "attributes.releaseDate",
                        reverse: true
                      }
                    ]}
                    containerStyle={{
                      display: "grid",
                      gridGap: "12px",
                      overflowX: "scroll",
                      scrollSnapType: "x proximity",
                      gridTemplateColumns: `repeat(100, ${targetWidth}px)`
                    }}
                    records={labelRecords}
                  >
                    <div className={styles.sectionHeader}>
                      {byArtistNameOnly
                        ? `${labelName}`
                        : `${labelName}, ${year}`}
                    </div>
                  </Shelf>
                </div>
              </div>
            )}
          </div>
          <div className={styles.contextRecord}>
            {discographyRecords && discographyRecords.length > 0 && (
              <div className={styles.catalogShelf}>
                <Shelf
                  recordClass={"library-bold-embedded-in-big"}
                  sortOptionIndex={0}
                  sortOptions={[
                    {
                      sortRecordsBy: "attributes.releaseDate",
                      reverse: true
                    }
                  ]}
                  scrollToSelectedRecord={true}
                  excludeRecords={[record]}
                  recordProps={{
                    allowTurnOver: true,
                    showCoverHud: true,
                    playOnHover: true
                  }}
                  records={discographyRecords}
                >
                  <div className={styles.sectionHeader}>
                    {record.attributes.artistName}
                  </div>
                </Shelf>
              </div>
            )}
          </div>
        </div>
      </ScrollView>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  let record = _.get(state, "lightweightRecord.selected.record");
  const isSelectedRecordTurnedOver =
    record &&
    _.get(state.record.turnedOverRecord, "recordId") ===
      bestRecord(state, record).id;

  return {
    isContainedInCatalyst: _.get(state.ui, "containerInfo.type") === "catalyst",
    recordSlugToDiscographyMap: state.record.recordSlugToDiscographyMap || {},
    recordIdToDetailedInfoMap: state.record.recordIdToDetailedInfoMap || {},
    recordSlugsWithNoImages: state.record.recordSlugsWithNoImages || {},

    clientHeight: state.ui.documentElement.clientHeight,
    clientWidth: state.ui.documentElement.clientWidth,
    record,
    isSelectedRecordTurnedOver,
    playingRecord: state.playback.currentlyPlayingRecord
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSelectionAnimationState: state =>
      dispatch({ type: SET_SELECTION_ANIMATION_STATE, state }),

    dismiss: () => {
      dispatch(clearSelectedRecord());
      setTimeout(
        () => dispatch({ type: TURN_BIG_RECORD_TO_SIDE, side: 0 }),
        SELECTION_ANIMATION_DURATION
      );
    },
    loadRecordIfNeeded: (record, forceLoad, callback) => {
      dispatch(loadRecordIfNeeded(record, forceLoad, callback));
    },
    loadDiscographyForRecord: (record, num) =>
      dispatch(loadDiscographyForRecord(record, num)),
    loadDetailedInfoForRecord: (record, num) =>
      dispatch(loadDetailedInfoForRecord(record, num))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectedRecord);

/*


 */
