import {
  CLEAR_BOOKMARK,
  loggedInPalette,
  loggedOutPalette,
  SET_BOOKMARK,
  SET_SWITCH,
  TOGGLE_SWITCH
} from "../reducers/ui-reducer";
import * as Vibrant from "node-vibrant";
import _ from "lodash";
import {
  SET_NORMAL_PALETTE,
  SET_PALETTE,
  SCROLL_Y_TO,
  SET_PICKED_ITEM,
  FLASH
} from "../reducers/ui-reducer";
import { bestRecord, jlog, sizedUrl, uiVariables } from "../helpers";
import * as d3color from "d3-color";
import { UPDATE_DOCUMENT_ELEMENT } from "../reducers/ui-reducer";
import { SET_RECORD_STORY_ZOOM_LEVEL } from "../reducers/ui-reducer";
import { store } from "../index";

export function isMe() {
  return store.getState().ui.meAlbumsCount === 3;
}

export function setPickedItem(itemData) {
  return function(dispatch) {
    dispatch({ type: SET_PICKED_ITEM, itemData });
  };
}
export function setNormalPalette() {
  return function(dispatch, getState) {
    const palette = getState().authentication.loggedIn
      ? loggedInPalette
      : loggedOutPalette;
    dispatch(setPalette(palette));
  };
}

export function setPalette(palette) {
  return function(dispatch, getState) {
    dispatch({ type: SET_PALETTE, palette });
    setImmediate(() => dispatch(initializePalette()));
  };
}

export function setSwitchValue(key, value) {
  return { type: SET_SWITCH, key, value };
}

export function toggleSwitch(key) {
  return { type: TOGGLE_SWITCH, key };
}

export function scrollYTo(y) {
  return { type: SCROLL_Y_TO, y };
}
export function clearScrollYTo() {
  return { type: SCROLL_Y_TO, y: null };
}

export function setRecordStoryZoomLevel(level) {
  return { type: SET_RECORD_STORY_ZOOM_LEVEL, level };
}
export function brighter(color, alpha = 0.2) {
  const c = d3color.color(color);
  //c.opacity = alpha;
  return c.brighter(alpha).toString();
}

export function updateDocumentElement(documentElement) {
  return {
    type: UPDATE_DOCUMENT_ELEMENT,
    documentElement
  };
}
export function darker(color, alpha = 0.4) {
  const c = d3color.color(color);
  //c.opacity = alpha;
  return c.darker(alpha).toString();
}

export function flash(message, kind = "notice") {
  return { type: FLASH, message, kind };
}

export function initializePalette() {
  // initialize palette css variables
  return function(dispatch, getState) {
    const { palette } = getState().ui;
    Object.keys(palette).map(k => {
      document.documentElement.style.setProperty(`--${k}`, palette[k]);
    });
  };
}
export function initializeUIVariables() {
  return function(dispatch, getState) {
    Object.keys(uiVariables).map(k => {
      const value = `${uiVariables[k].value}${uiVariables[k].units}`;
      document.documentElement.style.setProperty(`--${k}`, value);
    });
  };
}

export function setBookMark() {
  return { type: SET_BOOKMARK };
}

export function returnToBookmark() {
  return async function(dispatch, getState) {
    const state = getState();
    if (state.ui.bookmark) {
      dispatch(scrollYTo(state.ui.bookmark[1]));
      dispatch({ type: CLEAR_BOOKMARK });
    }
  };
}

export function setPaletteBasedOnRecord(record) {
  return function(dispatch, getState) {
    // const record = bestRecord(state, state.record.idToRecordMap[record.id]);
    const url = _.get(record, "attributes.artwork.url");
    if (!url) return;
    dispatch(setPaletteBasedOnImageUrl(url));
  };
}

export function setPaletteBasedOnImageUrl(url) {
  return function(dispatch, getState) {
    const state = getState();
    if (!url) return;
    const _sizedUrl = sizedUrl(url, 250);
    Vibrant.from(_sizedUrl)
      .getPalette()
      .then(imagePalette => {
        const palette = { ...state.ui.palette };
        const map = {
          // jaeHessBackgroundColor: `#${record.attributes.artwork.bgColor}`,
          // jaeHessTextColor: `#${record.attributes.artwork.textColor1}`,
          backgroundColor: imagePalette.LightMuted.hex,
          textColor: darker(imagePalette.LightMuted.hex),
          lightTextColor: brighter(imagePalette.LightMuted.hex),
          lighterBackgroundColor1: brighter(imagePalette.LightMuted.hex, 0.2),
          lighterBackgroundColor2: brighter(imagePalette.LightMuted.hex, 0.4),
          lighterBackgroundColor3: brighter(imagePalette.LightMuted.hex, 0.6),
          darkerBackgroundColor1: imagePalette.DarkMuted.hex,
          darkerBackgroundColor2: darker(imagePalette.DarkMuted.hex, 0.4),
          darkerBackgroundColor3: darker(imagePalette.DarkMuted.hex, 0.6),
          heavyColor: imagePalette.Muted.hex
        };
        // jlog({ settingPaletteForRecord: record.attributes.name });
        Object.keys(map).map(k => {
          // jlog({ k, v: map[k] });
          document.documentElement.style.setProperty(`--${k}`, map[k]);
        });
        dispatch({ type: SET_PALETTE, palette: { ...palette, ...map } });
      })
      .catch(err => {
        console.log(err);
      });
  };
}
