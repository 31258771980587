import { Schema } from "@orbit/data";


export const schema = new Schema({
  models: {
    playlist: {
      attributes: {
        name: {type: "string"},
        canEdit: {type: "boolean"},
        hasCatalog: {type: "boolean"}
      }
    },
    libraryPlaylist: {
      attributes: {
        name: {type: "string"},
        canEdit: {type: "boolean"},
        hasCatalog: {type: "boolean"}
      }
    },
    track: {
      attributes: {
        name: {type: "string"},
      }
    },
    librarySong: {
      attributes: {
        name: {type: "string"},
      }
    },
    album: {
      attributes: {
        artistName: {type: "string"},
        name: {type: "string"},
        releaseDate: {type:"date"},
        artwork: {type:"string"},
        playParams: {type: "string"},
        genreNames: {type: "string"},
        dateAdded: {type:"dateTime"}, // libraryAlbums only
        isSingle: {type: "boolean"}, // catalogAlbums only
        isCompilation: {type: "boolean"}, // catalogAlbums only
        isComplete: {type: "boolean"}, // catalogAlbums only
        recordLabel: {type: "string"}, // catalogAlbums only
        upc: {type:"string"}, // catalogAlbums only
        url: {type:"string"}, // catalogAlbums only
        trackCount:{type:"number"}, // catalogAlbums only
        dateTimeLastPlayed: {type:"dateTime"}, // made up by shelf
      }
    }
  }
});
