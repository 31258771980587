import { Flags } from "../helpers/flags";
import { flag, FlagValues } from "../helpers/flags";
export const TOGGLE_CATALOG_ACTIVE = "TOGGLE_CATALOG";

export const CATALOG_ID_APPLE_MUSIC_NEW_RELEASES = "appleMusicNewReleases";
export const CATALOG_ID_LABEL_NEW_RELEASES = "labelNewReleases";
export const CATALOG_ID_SPINITRON = "spinitron";
const catalogShape = {
  id: null,
  label: null
};

export const palette = [
  "#fb9a99",
  "#1f78b4",
  "#fdbf6f",
  "#6a3d9a",
  "#33a02c",
  "#a6cee3",
  "#e31a1c",
  "#ff7f00",
  "#cab2d6",
  "#b15928",
  "#b2df8a"
];

export const sourceColorMap = {
  [CATALOG_ID_APPLE_MUSIC_NEW_RELEASES]: palette[4],
  [CATALOG_ID_LABEL_NEW_RELEASES]: palette[1],
  [CATALOG_ID_SPINITRON]: palette[8]
};

const appleMusicNewReleases = {
  ...catalogShape,
  id: CATALOG_ID_APPLE_MUSIC_NEW_RELEASES,
  label: "From Apple Music",
  color: sourceColorMap[CATALOG_ID_APPLE_MUSIC_NEW_RELEASES]
};

const labelNewReleases = {
  ...catalogShape,
  id: CATALOG_ID_LABEL_NEW_RELEASES,
  label: "From labels you've been listening to",
  color: sourceColorMap[CATALOG_ID_LABEL_NEW_RELEASES]
};

const spinitron = {
  ...catalogShape,
  id: CATALOG_ID_SPINITRON,
  label: "Top 50 College Radio this week from Spinitron",
  color: sourceColorMap[CATALOG_ID_SPINITRON]
};

const defaultCatalogActiveMap = {
  [CATALOG_ID_APPLE_MUSIC_NEW_RELEASES]: true,
  [CATALOG_ID_LABEL_NEW_RELEASES]: true,
  [CATALOG_ID_SPINITRON]: true
};

const allCatalogs = [
  appleMusicNewReleases,
  labelNewReleases
  //spinitron
];
const initialState = {
  allCatalogs,
  catalogActiveMap: defaultCatalogActiveMap
};
const CatalogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_CATALOG_ACTIVE: {
      const { catalogId } = action;
      const mapCopy = { ...state.catalogActiveMap };
      mapCopy[catalogId] = !state.catalogActiveMap[catalogId];
      return { ...state, catalogActiveMap: mapCopy };
    }

    default:
      return initialState;
  }
};
export default CatalogsReducer;
