import React from "react";
import { areRecordsTheSame, bestRecord, sizedUrl } from "../../helpers";
import _ from "lodash";
import connect from "react-redux/es/connect/connect";

function CoverComponent(props) {
  const { record, id, widthHeight = 250, imgWidthHeight = 250 } = props;
  const rawImageUrl =
    props.coverArtImage || _.get(record.attributes, "artwork.url");

  const imageUrl = rawImageUrl && sizedUrl(rawImageUrl, imgWidthHeight);

  const imgStyle = {
    height: widthHeight,
    width: widthHeight,
    padding: 0,
    margin: 0,
    ...props.style
  };

  const style = {
    ...props.style
  };
  return (
    <div>
      <img
        id={id}
        onLoad={props.onImageLoad && props.onImageLoad()}
        src={imageUrl}
        style={imgStyle}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    coverArtImage: state.record.recordIdToCoverArtImageMap[ownProps.record.id]
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoverComponent);
